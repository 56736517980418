const initialState = {
    showTree: false,
    selectedLevel: null,
    selectedCodes: [],
};

export const cie10Reducer = (state = initialState, action) => {
    
    switch (action.type) {
      case "@cie10/showTree":
        return {
          ...state,
          showTree: true,
        };
      case "@cie10/hideTree":
        return {
          ...state,
          showTree: false
        };
      case "@cie10/addCode":
        return {
          ...state,
          selectedCodes: [...state.selectedCodes, action.payload]
        };
      case "@cie10/removeCode":
        return {
          ...state,
          selectedCodes: state.selectedCodes.filter(({codigo}) => codigo !== action.payload),
          selectedLevel: state.selectedCodes.filter(({codigo}) => codigo !== action.payload).length === 0 ? null: state.selectedLevel,
        };
      case "@cie10/updateCodes":
        return {
          ...state,
          selectedCodes: action.payload
        };
      case "@cie10/cleanCodes":
        return {
          ...state,
          selectedCodes: [],
          selectedLevel: null,
        };

      case "@cie10/updateLevel":
        return {
          ...state,
          selectedLevel: action.payload
        };
      default:
        return state;
    }
  };
  

