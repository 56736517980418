import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useComponentVisible } from "../../hooks/interactivity";
import { useGeoData } from "../../hooks/data";
import { selectFeature, unselectFeature } from "../../middleware/map";
import { layersConfig } from "../../config/layers";

const SearchFeature = ({ mapRef }) => {
  const [searchText, setSearchText] = useState("");
  const [placeholder, setPlaceholder] = useState("");
  const { selectedLayer } = useSelector((state) => state.layers);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible();
  const featuresList = useGeoData(selectedLayer);
  useEffect(() => {
    if (selectedLayer === "hospitals") {
      setPlaceholder("Busca un establecimiento...");
    }
    if (selectedLayer === "health-services") {
      setPlaceholder("Busca un servicio de salud...");
    }
    if (selectedLayer === "comunas") {
      setPlaceholder("Busca una comuna...");
    }
    if (selectedLayer === "regions") {
      setPlaceholder("Busca una región");
    }
  }, [selectedLayer, setPlaceholder]);
  return (
    <div className="flex flex-col my-2 flex-1">
      <label
        className="font-bold text-sm text-blue-900 uppercase tracking-wide hidden md:block"
        htmlFor="search"
      >
        Buscar
      </label>
      <div ref={ref} className="w-full relative">
        <input
          className="bg-slate-200 text-sm p-1 px-2 rounded-xl mt-1 w-full"
          id="search"
          type="text"
          value={searchText}
          onChange={({ target }) => {
            if (target.value.length > 0) {
              setIsComponentVisible(true);
            }
            setSearchText(target.value);
          }}
          onClick={({ target }) => {
            if (target.value.length > 0) {
              setIsComponentVisible(true);
            }
          }}
          placeholder={placeholder}
        ></input>

        {isComponentVisible ? (
          <div className="absolute mt-2 bg-slate-200 w-full z-[999] rounded-xl overflow-hidden bg-opacity-95">
            <ul className="max-h-60 overflow-y-scroll">
              {featuresList.length > 0 &&
                featuresList
                  .filter((f) => f.nombre)
                  .filter((f) => {
                    const comparisonText = searchText
                      .toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                      .normalize();

                    const comparisonName = f.nombre
                      .toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                      .normalize();

                    return comparisonName.includes(comparisonText);
                  })
                  .slice(0, 10)
                  .map(({ id, nombre, centerx, centery }) => (
                    <li
                      className="px-2 py-1 hover:bg-slate-400 cursor-pointer"
                      onClick={() => {
                        setIsComponentVisible(false);

                        

                        setSearchText("");

                        if (!centerx || !centery) {
                          unselectFeature(mapRef);
                          selectFeature(mapRef, {
                            id: id,
                            name: nombre,
                            displayType: layersConfig.find(
                              (layer) => layer.name === selectedLayer
                            ).displayType,
                          });
                          return;
                        }
                        if (selectedLayer === "comunas") {
                          mapRef.current.flyTo({
                            center: [centerx, centery],
                            zoom: 9,
                            duration: 1500,
                          });
                        }

                        if (selectedLayer === "regions") {
                          mapRef.current.flyTo({
                            center: [centerx, centery],
                            zoom: 7,
                            duration: 1500,
                          });
                        }
                        if (selectedLayer === "hospitals") {
                          mapRef.current.flyTo({
                            center: [centerx, centery],
                            zoom: 14,
                            duration: 1500,
                          });
                        }

                        mapRef.current.once("moveend", () => {
                          unselectFeature(mapRef);
                          selectFeature(mapRef, {
                            id: id,
                            name: nombre,
                            displayType: layersConfig.find(
                              (layer) => layer.name === selectedLayer
                            ).displayType,
                          });
                        });
                      }}
                      key={id}
                    >
                      {nombre}
                    </li>
                  ))}
            </ul>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SearchFeature;
