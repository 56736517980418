import {
  LineChart,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Line,
} from "recharts";
import moment from "moment";
import { format } from "d3-format";
import { useSelector } from "react-redux";

moment().locale("es");

const UNITS_MAP = {
  actividad: "n° personas",
  defunciones: "n° personas",
  carga: "días - cama",
  dias_estada: "promedio días estada",
};

const LABEL_MAP = {
  actividad: "actividad",
  carga: "carga",
  dias_estada: "dias de estada",
  defunciones: "defunciones",
};
const COLORS = [
  "rgb(32,173,164)",
  "rgb(60, 66, 193)",
  "rgb(242,	118,	29)",
  "rgb(215, 49, 119)",
  "rgb(118,	124,	247)",
];
const CustomTooltip = ({ active, payload, label, variable }) => {
  
  const { period } = useSelector((state) => state.dates);
  if (active && payload && payload.length) {
    let dateDescription = "";
    if (period === "daily") {
      dateDescription = new Date(label).toLocaleDateString("es-CL", {
        weekday: "long",
        day: "numeric",
        year: "numeric",
        month: "long",
      });
    }

    if (period === "weekly") {
      dateDescription =
        "semana del " +
        new Date(label).toLocaleDateString("es-CL", {
          day: "numeric",
          year: "numeric",
          month: "long",
        });
    }

    if (period === "monthly") {
      dateDescription = new Date(label).toLocaleDateString("es-CL", {
        month: "long",
        year: "numeric",
      });
    }

    if (period === "yearly") {
      dateDescription = new Date(label).toLocaleDateString("es-CL", {
        year: "numeric",
      });
    }
    return (
      <div
        key={label}
        className="custom-tooltip bg-white shadow-md rounded-md p-2 text-xs bg-opacity-95"
      >
        <p className="label mb-2 font-bold">{`${dateDescription}`}</p>
        {payload.map((p, i) => (
          <p key={i} className="field">
            <span className="font-bold" style={{ color: p.color }}>
              {p.name}:{" "}
            </span>
            {format(",")(p.value)}
          </p>
        ))}

        {
          variable !== 'dias_estada' &&
          <>
            <div className="bg-slate-300 w-full h-[1px] my-2"></div>
            <p className="field">
            <span className="font-bold text-blue-900">Total: </span>
            {format(",")(payload.reduce((acc, p) => acc + p.value, 0))}
          </p>
        </>
        }
        
      </div>
    );
  }

  return null;
};

const Chart = ({
  group,
  data,
  isMaximized,
  selectedVariable = "actividad",
}) => {
  const { period } = useSelector((state) => state.dates);
  const { selectedCodes, selectedLevel } = useSelector((state) => state.cie10);
  return (
    <div
      className={`chart-wrapper relative flex h-full mx-auto justify-center flex-col items-center`}
    >
      <ResponsiveContainer
        width={isMaximized ? "100%" : "100%"}
        height={isMaximized ? 400 : 280}
      >
        <LineChart
          data={data}
          margin={{ top: 35, left: 0, right: 20, bottom: 20 }}
        >
          <text
            x={60}
            y={18}
            fill="black"
            fontWeight="bold"
            textAnchor="left"
            dominantBaseline="central"
          >
            <tspan fontSize={isMaximized ? 16 : 14} fill="#1e3a8a">
              {`Evolución de ${LABEL_MAP[selectedVariable]} en el tiempo.`}
            </tspan>
          </text>
          <CartesianGrid strokeDasharray="3 3" />
          {group && (
            <Legend
              layout="horizontal"
              verticalAlign="bottom"
              iconType="line"
              fontSize={11}
              wrapperStyle={{
                bottom: 0,
                left: 25,
                fontSize: 13,
              }}
            />
          )}
          <XAxis
            dataKey="fecha"
            type="number"
            label={{
              value: "fecha",
              fill: "#1e3a8a",
              dy: 20,
            }}
            stroke={"#1e3a8a"}
            domain={["dataMin", "auto"]}
            scale="time"
            tickFormatter={(tickItem) => {
              if (period === "daily" || period === "weekly")
                return moment(new Date(tickItem)).format("DD/MM/YY");
              if (period === "monthly")
                return moment(new Date(tickItem)).format("MMM YYYY");
              if (period === "yearly")
                return moment(new Date(tickItem)).format("YYYY");
            }}
            tick={{ fill: "#1e3a8a", fontSize: 11, fontFamily: "Lato" }}
          />
          <YAxis
            domain={[0, "auto"]}
            stroke={"#1e3a8a"}
            label={{
              value: UNITS_MAP[selectedVariable],
              fill: "#1e3a8a",
              angle: -90,
              dx: -15,
            }}
            allowDecimals={false}
            tickFormatter={(tickItem) => format("~s")(tickItem)}
            tick={{ fill: "#1e3a8a", fontSize: 12, fontFamily: "Lato" }}
          />

          <Tooltip content={<CustomTooltip variable={selectedVariable}/>} />
          {group === "edad" && (
            <>
              <Line
                type="monotone"
                dataKey="[0 - 19]"
                name="[0 - 19]"
                stroke="rgb(32,173,164)"
                dot={false}
                animationDuration={1000}
              />
              <Line
                type="monotone"
                dataKey="[20 - 39]"
                name="[20 - 39]"
                stroke="rgb(60, 66, 193)"
                dot={false}
                animationDuration={1000}
              />
              <Line
                type="monotone"
                dataKey="[40 - 59]"
                name="[40 - 59]"
                stroke="rgb(242,	118,	29)"
                dot={false}
                animationDuration={1000}
              />
              <Line
                type="monotone"
                dataKey="[60 - 79]"
                name="[60 - 79]"
                stroke="rgb(215, 49, 119)"
                dot={false}
                animationDuration={1000}
              />
              <Line
                type="monotone"
                dataKey="[80 - 99]"
                name="[80 - 99]"
                stroke="rgb(118,	124,	247)"
                dot={false}
                animationDuration={1000}
              />
            </>
          )}
          {group === "sexo" && (
            <>
              <Line
                type="monotone"
                dataKey="1"
                name="Hombres"
                stroke="#E3436B"
                dot={false}
                animationDuration={1000}
              />
              <Line
                type="monotone"
                dataKey="2"
                name="Mujeres"
                stroke="#048BA8"
                dot={false}
                animationDuration={1000}
              />
            </>
          )}
          {group === "prevision" && (
            <>
              <Line
                type="monotone"
                dataKey="1"
                name="FONASA"
                stroke="#6929c4"
                dot={false}
                animationDuration={1000}
              />
              <Line
                type="monotone"
                dataKey="2"
                name="ISAPRE"
                stroke="#1192e8"
                dot={false}
                animationDuration={1000}
              />
              <Line
                type="monotone"
                dataKey="3"
                name="CAPREDENA"
                stroke="#198038"
                dot={false}
                animationDuration={1000}
              />
              <Line
                type="monotone"
                dataKey="4"
                name="DIPRECA"
                stroke="#fa4d56"
                dot={false}
                animationDuration={1000}
              />
              <Line
                type="monotone"
                dataKey="5"
                name="SISA"
                stroke="#b28600"
                dot={false}
                animationDuration={1000}
              />
            </>
          )}
          {group === "diagnostico" &&
            selectedCodes.map(({ codigo }, i) => {
              return (
                <Line
                  type="monotone"
                  dataKey={codigo}
                  name={codigo}
                  stroke={COLORS[i]}
                  dot={false}
                  animationDuration={1000}
                />
              );
            })}
          {!group && (
            <>
              {selectedVariable === "actividad" && (
                <Line
                  type="monotone"
                  dataKey="actividad"
                  name="actividad"
                  stroke="#82ca9d"
                  dot={false}
                  animationDuration={1000}
                />
              )}
              {selectedVariable === "carga" && (
                <Line
                  type="monotone"
                  dataKey="carga"
                  name="carga"
                  stroke="#dc2626"
                  dot={false}
                  animationDuration={1000}
                />
              )}
              {selectedVariable === "dias_estada" && (
                <Line
                  type="monotone"
                  dataKey="dias_estada"
                  name="dias de estada"
                  stroke="#2596be"
                  dot={false}
                  animationDuration={1000}
                />
              )}
              {selectedVariable === "defunciones" && (
                <Line
                  type="monotone"
                  dataKey="defunciones"
                  name="defunciones"
                  stroke="#555"
                  dot={false}
                  animationDuration={1000}
                />
              )}
            </>
          )}
        </LineChart>
      </ResponsiveContainer>
      {selectedCodes.length > 0 && (
        <div className="w-full">
          <h3 className="text-blue-900 text-md font-bold mb-2">
            Códigos CIE10
          </h3>
          <ul className="list-disc flex flex-col gap-1">
            {selectedCodes.map(({ codigo, glosa }, i) => (
              <li
                key={i}
                style={{
                  backgroundColor: COLORS[i],
                }}
                className="text-white rounded-lg p-2"
              >
                <p className="text-justify text-sm">
                  <span className="font-bold"><span className="uppercase">{selectedLevel}</span> {codigo}: </span>
                  {glosa}
                </p>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
export default Chart;
