const initialState = { selected: null, hovered: null, hoveredName: null };

export const featuresReducer = (state = initialState, action) => {
  switch (action.type) {
    case "@features/selectFeature":
      return {
        ...state,
        selected: {
          id: action.payload.featureId,
          name: action.payload.featureName,
          type: action.payload.featureType,
        },
      };
    case "@features/hoverFeature":
      return {
        ...state,
        hovered: action.payload.featureId,
        hoveredName: action.payload.featureName,
      };
    case "@features/unhoverFeature":
      return { ...state, hovered: null };
    case "@features/unselectFeature":
      return { ...state, selected: null };
    default:
      return state;
  }
};
