import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faMap, faBook, faVideo } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import ISCILogo from "../../assets/Logo-ISCI.png";
import INDLogo from "../../assets/Logo-IND.jpeg";

import "./Landing.css";
import { Link } from "react-router-dom";

const Landing = ({ visible, setVisible }) => {
  const { loading } = useSelector((state) => state.map);
  return (
    <div
      id="landing"
      className={`absolute inset-0 flex flex-col justify-center z-[9999] gap-10 ${
        visible
          ? "animate-bg opacity-100 translate-y-0"
          : "opacity-0 -translate-y-full"
      } transition-all duration-1000`}
    >
      <h1 className="text-center text-3xl md:text-4xl 2xl:text-5xl font-bold text-slate-100 drop-shadow-md tracking-wider">
        Plataforma Salud | ISCI
      </h1>

      <p className="text-center text-slate-200 text-md 2xl:text-xl font-bold drop-shadow-sm">
        Visualizador data egresos hospitalarios y defunciones del DEIS
      </p>
      <div className="flex justify-center gap-3">
        <button
          className="text-sm md:text-md xl:text-lg bg-blue-700 rounded-full text-white py-2 px-4 font-bold drop-shadow-sm hover:bg-blue-600 hover:scale-110 transition-all duration-500"
          onClick={() => {setVisible(false);}}
        >
          {loading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            <span>
              Ir al mapa <FontAwesomeIcon className="ml-2" icon={faMap} />
            </span>
          )}
        </button>
        <Link
          to="/docs"
          
          className="text-sm md:text-md xl:text-lg bg-slate-200 rounded-full text-blue-900 font-bold py-2 px-4 drop-shadow-sm hover:bg-slate-300 hover:scale-110 transition-all duration-500"
        >
          Ver documentación <FontAwesomeIcon className="ml-2" icon={faBook} />
        </Link>
      </div>
      <div className="flex justify-center">
        <a className="text-slate-100 underline hover:scale-110 transition-all" target="_blank" href="https://www.youtube.com/watch?v=pTkkQd7SyAo" rel="noreferrer">Ver vídeo tutorial <FontAwesomeIcon className="ml-2" icon={faVideo} /></a>
      </div>
      <div className="absolute bottom-0 2xl:bottom-5 w-full p-2">
        <div>
          <div className="flex justify-center items-center gap-6">
            <img
              src={ISCILogo}
              alt="Logo ISCI"
              className="h-8 drop-shadow-lg"
            />
            <img
              src={INDLogo}
              alt="Logo Ingenierìa Industrial Universidad de Chile"
              className="h-10 2xl:h-16 drop-shadow-lg"
            />
          </div>
        </div>
        <div className="my-8">
          <p className="text-center text-slate-100 text-md md:text-lg font-bold drop-shadow-sm tracking-wider">
            Susana Mondschein, Felipe Subiabre, Natalia Yankovic
          </p>

          <p className="text-center text-slate-100 text-sm md:text-md font-bold drop-shadow-sm mt-2 tracking-wide">
            Desarrollado por Sebastián C. Espinoza
          </p>
          <p className="text-center text-slate-100 text-sm md:text-md font-bold drop-shadow-sm italic mt-2 tracking-wider">
            <a href="mailto:plataformasalud@isci.cl">plataformasalud@isci.cl</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Landing;
