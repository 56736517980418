import { store } from "../store";
import { removeFeatureState, addFeatureState } from "../helpers/features";
import { layersConfig } from "../config/layers";

export const unselectFeature = ({ current: map }) => {
  const { layers, features } = store.getState();
  if (!features.selected) return;
  const layerInfo = layersConfig.find(
    (layer) => layer.name === layers.selectedLayer
  );
  const featureProps = {
    source: layerInfo.name,
    sourceLayer: layerInfo["source-layer"],
    id: features.selected.id,
  };
  removeFeatureState(map, featureProps, "selected");
  store.dispatch({ type: "@features/unselectFeature" });
};

export const selectFeature = ({ current: map }, featureProperties) => {
  const { layers } = store.getState();
  const layerInfo = layersConfig.find(
    (layer) => layer.name === layers.selectedLayer
  );
  const featureProps = {
    source: layerInfo.name,
    sourceLayer: layerInfo["source-layer"],
    id: featureProperties.id,
  };

  addFeatureState(map, featureProps, "selected");

  store.dispatch({
    type: "@features/selectFeature",
    payload: {
      featureId: featureProperties.id,
      featureName: featureProperties.name,
      featureType: layerInfo.displayType,
    },
  });
};
