import "./App.css";
import { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import MapHome from "./pages/MapHome";
import Docs from "./pages/Docs";
import ReactGA from 'react-ga4';

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
ReactGA.initialize(TRACKING_ID);

const router = createBrowserRouter([
  {
    path: "/",
    element: <MapHome />,
  },
  {
    path: "/docs",
    element: <Docs />,
  },
]);

function App() {
  useEffect(() => {
    ReactGA.send({hitType: "pageview", page: window.location.pathname + window.location.search});
  }, []);
  return <RouterProvider router={router} />;
}

export default App;
