import Landing from "../components/Landing/Landing";
import Map from "../components/Map/Map";
import ControlPanel from "../components/ControlPanel/ControlPanel";
import Dashboard from "../components/Dashboard/Dashboard";
import { useRef, useState } from "react";
import { ToastContainer } from "react-toastify";
import FeatureTooltip from "../components/FeatureTooltip/FeatureTooltip";
import DashboardButton from "../components/DashboardButton/DashboardButton";
import Cie10Tree from "../components/Cie10Tree/Cie10Tree";
import { useSelector } from "react-redux";

const MapHome = () => {
  const map = useRef(null);
  const [isLandingVisible, setLandingVisible] = useState(true);
  const [isDashboardVisible, setDashboardVisible] = useState(true);
  const cie10State = useSelector(state => state.cie10);
  return (
    <main className="w-screen overflow-hidden h-screen box-border p-0 m-0 relative">
      <ToastContainer position="bottom-center" />
      
      <Landing visible={isLandingVisible} setVisible={setLandingVisible} />
      <Map mapRef={map} />
      {!isLandingVisible && (
        <>
          <ControlPanel mapRef={map} />
          <Dashboard
            mapRef={map}
            visible={isDashboardVisible}
            setVisible={setDashboardVisible}
          />
          <DashboardButton
            visible={isDashboardVisible}
            setVisible={setDashboardVisible}
          />
          <FeatureTooltip />
          {cie10State.showTree && <Cie10Tree />}
        </>
      )}
    </main>
  );
};

export default MapHome;
