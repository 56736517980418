import { useContext, createContext, useState } from "react";
import Moment from "moment";
import "moment/locale/es";
import { extendMoment } from "moment-range";
import Dropdown from "../Dropdown/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { useComponentVisible } from "../../hooks/interactivity";
import {
  faCalendar,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const moment = extendMoment(Moment);
moment.locale("es");

const dateSelectionContext = createContext(null);

const rangeOptions = [
  {
    value: "yearly",
    text: "Anuales",
  },
  {
    value: "monthly",
    text: "Mensuales",
  },
  {
    value: "weekly",
    text: "Semanales",
  },
  {
    value: "daily",
    text: "Diarios",
  },
];

const weekDays = ["L", "M", "M", "J", "V", "S", "D"];

const CalendarDays = ({ year, month, clickEvent }) => {
  const { dateRange } = useContext(dateSelectionContext);

  const startDate = new Date(year, month, 1);
  const endDate = new Date(year, month + 1, 0);
  const startMonday = moment(startDate).startOf("isoweek");
  const endSunday = moment(endDate).endOf("isoweek");
  const cellFormat = (date) => {
    const setBackgroundAndTextColors = () => {
      if (
        date.format("YYYY-MM-DD") === dateRange.start ||
        date.format("YYYY-MM-DD") === dateRange.end
      )
        return "bg-blue-600 text-white rounded-full";

      if (date.month() !== month) return "text-slate-400 hover:bg-blue-200";

      if (moment(date)) return "hover:bg-blue-400 hover:text-white";
    };
    return `text-xs h-7 aspect-square flex items-center justify-center 
        hover:rounded-[20px] transition-all cursor-pointer duration-500
        ${setBackgroundAndTextColors(date)}
        `;
  };
  const rows = Array.from(
    moment.range(startMonday, endSunday).by("day")
  ).reduce((acc, d, i) => {
    const formatCell = (d) => (
      <td
        key={d}
        onClick={() => clickEvent(d)}
        className={`${
          moment
            .range(moment(dateRange.start), moment(dateRange.end))
            .contains(d)
            ? "bg-blue-100"
            : ""
        }
        ${
          dateRange.start === d.format("YYYY-MM-DD") || i % 7 === 0
            ? "rounded-l-full"
            : ""
        }
        ${
          dateRange.end === d.format("YYYY-MM-DD") || i % 7 === 6
            ? "rounded-r-full"
            : ""
        }        
            `}
      >
        <div className={cellFormat(d)}>{d.format("DD")} </div>
      </td>
    );

    if (i % 7 === 0) {
      acc.push([]);
    }

    return [
      ...acc.slice(0, acc.length - 1),
      [...acc[acc.length - 1], formatCell(d)],
    ];
  }, []);

  const header = weekDays.map((day, i) => (
    <th key={i}>
      <div className="aspect-square h-7 text-xs bg-blue-400 text-white font-medium flex items-center justify-center rounded-full cursor-default">
        {day}
      </div>
    </th>
  ));

  return (
    <div className="p-1 mt-2 ">
      <table>
        <thead>
          <tr className="flex justify-center">{header}</tr>
        </thead>

        <tbody className="flex flex-col space-y-1 mt-2">
          {rows.map((week, i) => (
            <tr className="flex justify-center" key={i}>
              {week}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const CalendarMonths = ({ year, month, clickEvent }) => {
  const itemsPerRow = 4;
  const { dateRange } = useContext(dateSelectionContext);
  const startDate = new Date(year, 0, 1);
  const endDate = new Date(year, 11, 1);
  const startMonth = moment(startDate);
  const endMonth = moment(endDate);
  const cellFormat = (date) => {
    const setBackgroundAndTextColors = () => {
      if (
        date.format("YYYY-MM-DD") ===
          moment(dateRange.start).startOf("month").format("YYYY-MM-DD") ||
        date.format("YYYY-MM-DD") ===
          moment(dateRange.end).startOf("month").format("YYYY-MM-DD")
      )
        return "bg-blue-600 text-white rounded-full";

      if (date.month() !== month) return "text-slate-400 hover:bg-blue-200";

      if (moment(date)) return "hover:bg-blue-400 hover:text-white";
    };
    return `text-xs h-7 w-10 flex items-center justify-center 
        hover:rounded-[20px] transition-all cursor-pointer duration-500
        ${setBackgroundAndTextColors(date)}
        `;
  };
  const rows = Array.from(
    moment.range(startMonth, endMonth).by("month")
  ).reduce((acc, d, i) => {
    const formatCell = (d) => (
      <td
        key={d}
        onClick={() => clickEvent(d)}
        className={`${
          moment
            .range(moment(dateRange.start), moment(dateRange.end))
            .contains(d)
            ? "bg-blue-100"
            : ""
        }
        ${
          dateRange.start === d.format("YYYY-MM-DD") || i % itemsPerRow === 0
            ? "rounded-l-full"
            : ""
        }
        ${
          dateRange.end === d.format("YYYY-MM-DD") ||
          i % itemsPerRow === itemsPerRow - 1
            ? "rounded-r-full"
            : ""
        }        
            `}
      >
        <div className={cellFormat(d)}>{d.format("MMM")} </div>
      </td>
    );

    if (i % itemsPerRow === 0) {
      acc.push([]);
    }

    return [
      ...acc.slice(0, acc.length - 1),
      [...acc[acc.length - 1], formatCell(d)],
    ];
  }, []);

  return (
    <div className="p-1 mt-2 ">
      <table>
        <tbody className="flex flex-col space-y-1 mt-2">
          {rows.map((week, i) => (
            <tr className="flex justify-center" key={i}>
              {week}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const CalendarYears = ({ displayDates, clickEvent }) => {
  const itemsPerRow = 4;
  const { dateRange } = useContext(dateSelectionContext);
  const startYear = moment(displayDates.min);
  const endYear = moment(displayDates.max);

  const cellFormat = (date) => {
    const setBackgroundAndTextColors = () => {
      if (
        date.format("YYYY-MM-DD") ===
          moment(dateRange.start).startOf("year").format("YYYY-MM-DD") ||
        date.format("YYYY-MM-DD") ===
          moment(dateRange.end).startOf("year").format("YYYY-MM-DD")
      )
        return "bg-blue-600 text-white rounded-full";

      if (moment(date)) return "hover:bg-blue-400 hover:text-white";
    };
    return `text-xs h-7 w-10 flex items-center justify-center 
        hover:rounded-[20px] transition-all cursor-pointer duration-500
        ${setBackgroundAndTextColors(date)}
        `;
  };
  const rows = Array.from(moment.range(startYear, endYear).by("year")).reduce(
    (acc, d, i) => {
      const formatCell = (d) => (
        <td
          key={d}
          onClick={() => clickEvent(d)}
          className={`${
            moment
              .range(moment(dateRange.start), moment(dateRange.end))
              .contains(d)
              ? "bg-blue-100"
              : ""
          }
        ${
          dateRange.start === d.format("YYYY-MM-DD") || i % itemsPerRow === 0
            ? "rounded-l-full"
            : ""
        }
        ${
          dateRange.end === d.format("YYYY-MM-DD") ||
          i % itemsPerRow === itemsPerRow - 1
            ? "rounded-r-full"
            : ""
        }        
            `}
        >
          <div className={cellFormat(d)}>{d.format("YYYY")} </div>
        </td>
      );

      if (i % itemsPerRow === 0) {
        acc.push([]);
      }

      return [
        ...acc.slice(0, acc.length - 1),
        [...acc[acc.length - 1], formatCell(d)],
      ];
    },
    []
  );
  console.log(rows);

  return (
    <div className="p-1 mt-2 ">
      <table>
        <tbody className="flex flex-col space-y-1 mt-2">
          {rows.map((week, i) => (
            <tr className="flex justify-center" key={i}>
              {week}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
const Calendar = ({ year, month, clickEvent }) => {
  const { period } = useContext(dateSelectionContext);
  if (period === "daily" || period === "weekly")
    return <CalendarDays year={year} month={month} clickEvent={clickEvent} />;

  if (period === "monthly")
    return <CalendarMonths year={year} clickEvent={clickEvent} />;
  if (period === "yearly")
    return (
      <CalendarYears
        displayDates={{ min: "2001-01-01", max: "2020-12-31" }}
        clickEvent={clickEvent}
      />
    );
};
const CalendarPanel = ({
  title,
  displayDate,
  calendarDate,
  dateRange,
  dateDomain,
  period,
  setCalendarDate,
  clickEvent,
}) => (
  <div className="flex flex-col items-center">
    <div className="w-full flex justify-between text-xs text-slate-500 text-left px-2 mb-1">
      <p className="">{title}</p>
      <p>{displayDate}</p>
    </div>
    {period !== "yearly" && (
      <PeriodSelector
        content={moment(calendarDate).format("YYYY")}
        date={calendarDate}
        dateDomain={dateDomain}
        onClickLeft={() => {
          const newDate = moment(calendarDate)
            .subtract(1, "years")
            .format("YYYY-MM-DD");
          if (newDate < dateDomain[0]) return;
          setCalendarDate(newDate);
        }}
        onClickRight={() => {
          const newDate = moment(calendarDate)
            .add(1, "years")
            .format("YYYY-MM-DD");
          if (newDate > dateDomain[1]) return;
          setCalendarDate(newDate);
        }}
      />
    )}
    {(period === "daily" || period === "weekly") && (
      <PeriodSelector
        content={moment(calendarDate).format("MMM")}
        dateDomain={dateDomain}
        date={calendarDate}
        setCalendarDate={setCalendarDate}
        onClickLeft={() => {
          const newDate = moment(calendarDate)
            .subtract(1, "months")
            .format("YYYY-MM-DD");
          if (newDate < dateDomain[0]) return;
          setCalendarDate(newDate);
        }}
        onClickRight={() => {
          const newDate = moment(calendarDate)
            .add(1, "months")
            .format("YYYY-MM-DD");
          if (newDate > dateDomain[1]) return;
          setCalendarDate(newDate);
        }}
      />
    )}

    <dateSelectionContext.Provider value={{ dateRange, period }}>
      <Calendar
        year={moment(calendarDate).year()}
        month={moment(calendarDate).month()}
        clickEvent={clickEvent}
      />
    </dateSelectionContext.Provider>
  </div>
);
const PeriodSelector = ({
  content,
  date,
  dateDomain,
  onClickLeft,
  onClickRight,
}) => (
  <div className="w-full flex justify-between items-center text-xs">
    <FontAwesomeIcon
      className={`hover:scale-125 transition-all select-none ${
        moment(date).startOf("month").format("YYYY-MM-DD") === dateDomain[0] ||
        moment(date).startOf("year").format("YYYY-MM-DD") === dateDomain[0]
          ? "text-slate-400 cursor-default"
          : "text-slate-700 cursor-pointer"
      }`}
      icon={faChevronLeft}
      onClick={onClickLeft}
    />
    <span className="text-xs font-md text-slate-700 cursor-default select-none">
      {content}
    </span>
    <FontAwesomeIcon
      className={`hover:scale-125 transition-all select-none ${
        moment(date).endOf("month").format("YYYY-MM-DD") === dateDomain[1] ||
        moment(date).endOf("year").format("YYYY-MM-DD") === dateDomain[1]
          ? "text-slate-400 cursor-default"
          : "text-slate-700 cursor-pointer"
      }`}
      icon={faChevronRight}
      onClick={onClickRight}
    />
  </div>
);

const DateRangePicker = () => {
  const dateDomain = { start: "2001-01-01", end: "2020-12-31" };
  const { dateRange, period } = useSelector((state) => state.dates);
  const [leftCalendar, setLeftCalendar] = useState(
    moment(dateRange.start).startOf("month").format("YYYY-MM-DD")
  );
  const [rightCalendar, setRightCalendar] = useState(
    moment(dateRange.start).startOf("month").format("YYYY-MM-DD")
  );
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const dispatch = useDispatch();
  return (
    <div className="mt-3">
      <h2 className="text-sm uppercase text-blue-900 font-bold">Fecha</h2>
      <label className="text-sm text-slate-700">Ver datos</label>
      <Dropdown
        className={"mt-0"}
        options={rangeOptions}
        selectedValue={period}
        onChange={(value) => {
          dispatch({ type: "@dates/setPeriod", payload: value });
          if (value === "weekly") {
            const newStart =
              moment(dateRange.start).startOf("isoweek") <
              moment(dateDomain.start)
                ? moment(dateDomain.start)
                : moment(dateRange.start).startOf("isoweek");

            const newEnd =
              moment(dateRange.end).endOf("isoweek") > moment(dateDomain.end)
                ? moment(dateDomain.end)
                : moment(dateRange.end).endOf("isoweek");
            dispatch({
              type: "@dates/setStartDate",
              payload: newStart.format("YYYY-MM-DD"),
            });
            dispatch({
              type: "@dates/setEndDate",
              payload: newEnd.format("YYYY-MM-DD"),
            });
          }

          if (value === "monthly") {
            const newStart =
              moment(dateRange.start).startOf("month") <
              moment(dateDomain.start)
                ? moment(dateDomain.start)
                : moment(dateRange.start).startOf("month");

            const newEnd =
              moment(dateRange.end).endOf("month") > moment(dateDomain.end)
                ? moment(dateDomain.end)
                : moment(dateRange.end).endOf("month");
            dispatch({
              type: "@dates/setStartDate",
              payload: newStart.format("YYYY-MM-DD"),
            });
            dispatch({
              type: "@dates/setEndDate",
              payload: newEnd.format("YYYY-MM-DD"),
            });
          }
          if (value === "yearly") {
            const newStart =
              moment(dateRange.start).startOf("year") < moment(dateDomain.start)
                ? moment(dateDomain.start)
                : moment(dateRange.start).startOf("year");

            const newEnd =
              moment(dateRange.end).endOf("year") > moment(dateDomain.end)
                ? moment(dateDomain.end)
                : moment(dateRange.end).endOf("year");

            dispatch({
              type: "@dates/setStartDate",
              payload: newStart.format("YYYY-MM-DD"),
            });
            dispatch({
              type: "@dates/setEndDate",
              payload: newEnd.format("YYYY-MM-DD"),
            });
          }
        }}
      />

      <div ref={ref} className="relative">
        <button
          className={`active:border-blue-700 border-2 w-full mt-2 p-1 flex justify-between items-center text-slate-600 rounded-xl bg-blue-100 px-2
          ${isComponentVisible ? "border-blue-400" : ""}`}
          onClick={() => {
            if (!isComponentVisible) {
              setLeftCalendar(dateRange.start);
              setRightCalendar(dateRange.end);
            }
            setIsComponentVisible(!isComponentVisible);
          }}
        >
          <span className="text-sm">{`${moment(dateRange.start).format(
            "DD/MM/YYYY"
          )} - ${moment(dateRange.end).format("DD/MM/YYYY")}`}</span>
          <FontAwesomeIcon
            className={`transition-all duration-500 ${
              isComponentVisible ? "text-blue-400" : "text-slate-400"
            }`}
            icon={faCalendar}
          />
        </button>
        {isComponentVisible && (
          <div
            className={`shadow-md absolute top-10 p-3 left-0 rounded-md bg-slate-100 flex flex-col max-w-[90vw]  md:max-w-none overflow-x-scroll md:overflow-x-auto`}
          >
            <div className="flex justify-between items-stretch md:flex-row">
              <CalendarPanel
                title={"Desde"}
                displayDate={moment(dateRange.start).format("ll")}
                calendarDate={leftCalendar}
                dateRange={dateRange}
                dateDomain={dateDomain}
                period={period}
                setCalendarDate={setLeftCalendar}
                clickEvent={(d) => {
                  console.log(d);
                  if (moment(dateRange.end) > d) {
                    if (period === "daily") {
                      if (d < moment(dateDomain.start)) {
                        dispatch({
                          type: "@dates/setStartDate",
                          payload: moment(dateDomain.start).format(
                            "YYYY-MM-DD"
                          ),
                        });
                      } else {
                        dispatch({
                          type: "@dates/setStartDate",
                          payload: d.format("YYYY-MM-DD"),
                        });
                      }
                    }
                    if (period === "weekly") {
                      if (d.startOf("isoweek") < moment(dateDomain.start)) {
                        dispatch({
                          type: "@dates/setStartDate",
                          payload: moment(dateDomain.start).format(
                            "YYYY-MM-DD"
                          ),
                        });
                      } else {
                        dispatch({
                          type: "@dates/setStartDate",
                          payload: d.startOf("isoweek").format("YYYY-MM-DD"),
                        });
                      }
                    }
                    if (period === "monthly") {
                      if (d.startOf("month") < moment(dateDomain.start)) {
                        dispatch({
                          type: "@dates/setStartDate",
                          payload: moment(dateDomain.start).format(
                            "YYYY-MM-DD"
                          ),
                        });
                      } else {
                        dispatch({
                          type: "@dates/setStartDate",
                          payload: d.startOf("month").format("YYYY-MM-DD"),
                        });
                      }
                    }
                    if (period === "yearly") {
                      if (d.startOf("year") < moment(dateDomain.start)) {
                        dispatch({
                          type: "@dates/setStartDate",
                          payload: moment(dateDomain.start).format(
                            "YYYY-MM-DD"
                          ),
                        });
                      } else {
                        dispatch({
                          type: "@dates/setStartDate",
                          payload: d.startOf("year").format("YYYY-MM-DD"),
                        });
                      }
                    }
                  } else {
                    toast.error(
                      `Selecciona una fecha menor al ${moment(
                        dateRange.end
                      ).format("DD/MM/YYYY")}`,
                      {
                        position: "top-center",
                        autoClose: 1500,
                        hideProgressBar: true,
                      }
                    );
                  }
                }}
              />
              <div className="w-[2px] mx-2 h-full bg-slate-400"></div>
              <CalendarPanel
                title={"Hasta"}
                displayDate={moment(dateRange.end).format("ll")}
                calendarDate={rightCalendar}
                dateRange={dateRange}
                dateDomain={dateDomain}
                period={period}
                setCalendarDate={setRightCalendar}
                clickEvent={(d) => {
                  if (moment(dateRange.start) < d) {
                    if (period === "daily") {
                      if (d > moment(dateDomain.end)) {
                        dispatch({
                          type: "@dates/setEndDate",
                          payload: moment(dateDomain.end).format("YYYY-MM-DD"),
                        });
                      } else {
                        dispatch({
                          type: "@dates/setEndDate",
                          payload: d.format("YYYY-MM-DD"),
                        });
                      }
                    }

                    if (period === "weekly") {
                      if (d.endOf("isoweek") > moment(dateDomain.end)) {
                        dispatch({
                          type: "@dates/setEndDate",
                          payload: moment(dateDomain.end).format("YYYY-MM-DD"),
                        });
                      } else {
                        dispatch({
                          type: "@dates/setEndDate",
                          payload: d.endOf("isoweek").format("YYYY-MM-DD"),
                        });
                      }
                    }
                    if (period === "monthly") {
                      if (d.endOf("month") > moment(dateDomain.end)) {
                        dispatch({
                          type: "@dates/setEndDate",
                          payload: moment(dateDomain.end).format("YYYY-MM-DD"),
                        });
                      } else {
                        dispatch({
                          type: "@dates/setEndDate",
                          payload: d.endOf("month").format("YYYY-MM-DD"),
                        });
                      }
                    }
                    if (period === "yearly") {
                      if (d.endOf("year") > moment(dateDomain.end)) {
                        dispatch({
                          type: "@dates/setEndDate",
                          payload: moment(dateDomain.end).format("YYYY-MM-DD"),
                        });
                      } else {
                        dispatch({
                          type: "@dates/setEndDate",
                          payload: d.endOf("year").format("YYYY-MM-DD"),
                        });
                      }
                    }
                  } else {
                    toast.error(
                      `Selecciona una fecha mayor al ${moment(
                        dateRange.start
                      ).format("DD/MM/YYYY")}`,
                      {
                        position: "top-center",
                        autoClose: 1500,
                        hideProgressBar: true,
                      }
                    );
                  }
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DateRangePicker;
