import { useSelector } from "react-redux";
import { layersConfig } from "../../config/layers";

const FeatureTooltip = () => {
  const { hovered, hoveredName } = useSelector((state) => state.features);
  const { selectedLayer } = useSelector((state) => state.layers);
  const type = layersConfig.find(
    (layer) => layer.name === selectedLayer
  ).displayType;
  return (
    hovered && (
      <div className="absolute top-20 md:top-2 left-1/2 bg-white p-3 px-6 rounded-full text-sm  -translate-x-1/2 drop-shadow-md">
        <p className="font-bold text-blue-900 text-center">{type}</p>
        <p className="font-md text-xs text-center tracking-widest">
          {hoveredName}
        </p>
      </div>
    )
  );
};

export default FeatureTooltip;
