import { useComponentVisible } from "../../hooks/interactivity";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Dropdown = ({ options, selectedValue, onChange, className }) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  return (
    <div ref={ref} className={`relative ${className}`}>
      <button
        onClick={() => {
          setIsComponentVisible(!isComponentVisible);
        }}
        className={`text-sm relative w-full bg-blue-700 hover:bg-blue-800 text-white py-1 px-3 mt-1 rounded-xl text-left transition-all ${className} flex justify-between items-center`}
      >
        {options.find((o) => o.value === selectedValue).text}
        <FontAwesomeIcon
          className={`transition-all ${
            isComponentVisible ? "-rotate-180" : ""
          }`}
          icon={faChevronDown}
        />
      </button>
      {isComponentVisible && (
        <div
          className={`w-full max-h-60 overflow-y-scroll absolute overflow-hidden box-border bg-blue-100 mt-1 rounded-md drop-shadow-md transition-all z-50 text-slate-700`}
        >
          <ul className="block py-1 text-slate-700">
            {options.map((o) => (
              <li
                key={o.value}
                className="hover:bg-blue-200 px-3 py-1 transition-all cursor-pointer"
                onClick={() => {
                  setIsComponentVisible(false);
                  onChange(o.value);
                }}
              >
                {o.text}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
