import { useDispatch, useSelector } from "react-redux";
import Dropdown from "../Dropdown/Dropdown";
import { layersConfig } from "../../config/layers";
import { unselectFeature } from "../../middleware/map";
import DateRangePicker from "../DateRangePicker/DateRangePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import SearchFeature from "../SearchFeature/SearchFeature";
import { useState } from "react";
import Flag from '../../assets/flag.svg'
import { mapConfig } from "../../config/map";

const LayerSelector = ({ mapRef }) => {
  const { selectedLayer, scope } = useSelector((state) => state.layers);

  const dispatch = useDispatch();
  return (
    <div>
      <h3 className="text-sm uppercase text-blue-900 font-bold mt-2">
        División Geográfica
      </h3>
      <Dropdown
        options={layersConfig
          .filter((layer) => layer.scope.includes(scope))
          .map((layer) => ({
            value: layer.name,
            text: layer.showName,
          }))}
        selectedValue={selectedLayer}
        onChange={(value) => {
          unselectFeature(mapRef);
          dispatch({ type: "@layers/changeSelected", payload: value });
        }}
      />
    </div>
  );
};
const ControlPanel = ({ mapRef }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <div
        id="control-panel"
        className={` md:w-60 top-2 left-2 right-2 flex md:top-4 md:left-4 bg-white absolute drop-shadow-md px-4 py-2 rounded-xl
                    transition-all duration-1000
    `}
      >
        <div className="md:hidden flex-col gap-3 w-full">
          <div className="flex flex-grow items-center justify-between order-first gap-3">
            <SearchFeature mapRef={mapRef} />
            <button
              className="aspect-square text-blue-600 cursor-pointer"
              onClick={() => setOpen(!isOpen)}
            >
              <FontAwesomeIcon icon={faCog} />
            </button>
          </div>
          {isOpen && <LayerSelector mapRef={mapRef} />}

          {isOpen && <DateRangePicker />}
          {isOpen && (
            <div className="my-2">
              <Link
                to="/docs"
                className="text-blue-900 text-sm hover:text-blue-700"
              >
                Ver documentación{" "}
                <FontAwesomeIcon className="ml-2" icon={faBook} />
              </Link>
            </div>
          )}
        </div>

        <div className="hidden md:flex flex-col gap-2 w-full">
          <LayerSelector mapRef={mapRef} />
          <div className="flex justify-center text-blue-900">
            <button className="text-sm whitespace-nowrap flex items-center gap-1 hover:scale-105"
            onClick={() => {
              unselectFeature(mapRef)
              mapRef.current.flyTo({
                center: [mapConfig.lng, mapConfig.lat],
                zoom: mapConfig.zoom,
              })
              }}>
              <span>Ver datos a nivel país</span> <img className="h-4 rounded-sm ml-1"alt="Bandera de Chile" src={Flag} />
            </button>
          </div>
          <SearchFeature mapRef={mapRef} />
          <DateRangePicker />
          <Link to="/docs" className="text-blue-900 my-2 hover:text-blue-700">
            Ver documentación <FontAwesomeIcon className="ml-2" icon={faBook} />
          </Link>
        </div>
      </div>
    </>
  );
};

export default ControlPanel;
