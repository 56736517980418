const initialState = {
  dateRange: { start: "2020-01-01", end: "2020-12-31" },
  period: "monthly",
};

export const datesReducer = (state = initialState, action) => {
  //console.log("map reducer", state);
  switch (action.type) {
    case "@dates/setStartDate":
      return {
        ...state,
        dateRange: {
          start: action.payload,
          end: state.dateRange.end,
        },
      };
    case "@dates/setEndDate":
      return {
        ...state,
        dateRange: {
          start: state.dateRange.start,
          end: action.payload,
        },
      };

    case "@dates/seDateRange":
      return {
        ...state,
        dateRange: {
          start: action.payload.start,
          end: action.payload.end,
        },
      };
    case "@dates/setPeriod":
      return {
        ...state,
        period: action.payload,
      };
    default:
      return state;
  }
};
