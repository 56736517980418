import { useEffect, useRef } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { mapConfig } from "../config/map";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

export const useMap = (mapRef) => {
  const mapContainer = useRef(null);
  useEffect(() => {
    if (mapRef.current) return;
    mapRef.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: process.env.REACT_APP_MAPBOX_STYLE_URL,
      center: [mapConfig.lng, mapConfig.lat],
      zoom: mapConfig.zoom,
      minZoom: mapConfig.minZoom,
    });
    mapRef.current.addControl(new mapboxgl.NavigationControl(), 'bottom-left');
  });
  return mapContainer;
};
