const initialState = { map: null, loading: true };

export const mapReducer = (state = initialState, action) => {
  //console.log("map reducer", state);
  switch (action.type) {
    case "@map/loadMap":
      return {
        ...state,
        map: action.payload,
      };
    case "@map/setLoaded":
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
