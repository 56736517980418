import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import ISCILogo from "../assets/Logo-ISCI-dark.png";
import INDLogo from "../assets/Logo-IND.jpeg";
import Ficha from '../assets/ficha.jpg';
import "./Docs.css";
import { dataIntegrity } from "../data/integrity";
import { format } from "d3-format";
const formatN = format(',');
const Docs = () => {
  return (
    <>
    <main id="docs" className="container mx-auto p-8 bottom-0">
      <div className="flex justify-between w-full">
        <Link to="/">
          <h1 className="font-extrabold text-4xl text-blue-900 hover:text-blue-800 hover:">
            Plataforma Salud | ISCI
          </h1>
        </Link>
        <Link to="/">
          <button className="text-sm bg-blue-700 rounded-full text-white py-2 px-4 font-bold drop-shadow-sm hover:bg-blue-600 hover:scale-110 transition-all duration-500">
            Ir a Inicio <FontAwesomeIcon className="ml-2" icon={faHome} />
          </button>
        </Link>
      </div>
      <h2 className="text-2xl mt-10 text-blue-900 font-extrabold">
        Documentación visualizador data egresos hospitalarios y defunciones del DEIS
      </h2>
      <h2 className="uppercase text-blue-900 font-bold  -offset-2 text-xl mt-10">Video Tutorial</h2>
      <div className="flex justify-center my-5">
      <iframe className="w-full md:w-3/5 aspect-video" src="https://www.youtube.com/embed/pTkkQd7SyAo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <h2 className="uppercase text-blue-900 font-bold  -offset-2 text-xl mt-10">Disponibilidad de datos</h2>
      <p className="mt-2">Información utilizada corresponde a la publicada por el DEIS correspondiente a 31.113.114 registros de egresos hospitalarios desde 2001-01-01 hasta 2019-12-31. La base de defunciones cuenta con 3.386.880 registros desde 2001-01-01 hasta 2018-12-31. </p>
      <p>
        La ficha de egresos hospitalarios incorpora información sobre la atención en particular, incluyendo día de egreso y días de estadía.
        Para visualizar la data se consideró el día de ingreso hospitalario, restando los días de estadía a la fecha de egreso. 
      </p>
      <h2 className="uppercase text-blue-900 font-bold mt-5  -offset-2 text-xl">Integridad de los datos</h2>
      <p className="mt-2">
        Las visualizaciones construidas no consideran todos los datos disponibles en la base de datos original.
        Consideramos como datos válidos solo aquellos que tienen una fecha de egreso conocida (día, mes y año), ya que de lo contrario no es posible calcular fechas de ingreso confiables. Por esta razón los datos en los primeros años disponibles pueden mostrar resultados inconsistentes,
        sobre todo cuando se observa la información a niveles muy desagregados (para un establecimiento en particular, o para una sola patología, por ejemplo). A continuación se muestra una tabla con la disponibilidad de datos a través de los años.
      </p>
      <div className="max-w-[90vw] overflow-x-scroll overflow-y-auto">
      <table className="table-auto rounded-sm text-sm mt-4">
        <thead className="bg-slate-200">
          <tr className="border border-slate-200">
          <th className="px-2 py-2 text-left">Año</th>
          {dataIntegrity.map(({anio_egreso}) => <th className="px-2 py-2">{anio_egreso}</th>)}
          </tr>
        </thead>
        <tbody>
          <tr className="border border-slate-200">
            <td className="px-2 py-2">Total de registros</td>
            {dataIntegrity.map(({n}) => <td className="px-2 py-2">{formatN(n)}</td>)}
          </tr>
          <tr className="border border-slate-200">
            <td className="px-2 py-2">Total de registros válidos</td>
            {dataIntegrity.map(({n_fecha}) => <td className="px-2 py-2"> {formatN(n_fecha)}</td>)}
          </tr>
          <tr className="border border-slate-200">
            <td className="px-2 py-2">Porcentaje</td>
            {dataIntegrity.map(({p}) => <td className="px-2 py-2">{Math.round(p*10)/10}%</td>)}
          </tr>
        </tbody>
      </table>
      </div>
      <h2 className="uppercase text-blue-900 font-bold mt-5  -offset-2 text-xl">Glosario de Términos</h2>
      <p className="mt-5 text-md"><span className="text-blue-900 font-bold ">ACTIVIDAD:</span> recuento de ingresos hospitalarios para una fecha en particular.
        En el caso de agregación semanal, mensual o anual, se consideró la suma de ingresos para todo el período en cuestión.
        Notar que dado que la información proviene de la ficha de egreso hacia el final del horizonte de la data se observa una baja sistemática en la actividad,
        puesto que son pacientes que posiblemente hayan ingresado, pero que aún no podemos contabilizar al no haber sido dados de alta.</p>
      <p className="mt-5 text-md"><span className="text-blue-900 font-bold ">CARGA:</span> pacientes para la visualización diaria, la carga corresponde a las camas ocupadas
      en un día en particular.  En caso de visualización agregada por semanas, menses o año, la carga reportada son todos los días cama ocupados durante el período en cuestión.
      Así, para saber la ocupación hospitalaria promedio en el caso de la visualización semanal debemos dividir en 7, y en el caso de mensual o anual por los días correspondientes al período seleccionado.</p>
      <p className="mt-5 text-md"><span className="text-blue-900 font-bold ">DÍAS DE ESTADA:</span> número de días reportado en la ficha. Es importante notar que este número considera a todos
      los pacientes que ingresaron durante el período de tiempo seleccionado, reportado según la frecuencia establecida (diario, semanal, mensual o anual).</p>
      <p className="mt-5 text-md"><span className="text-blue-900 font-bold ">DEFUNCIONES:</span> la visualización considera el día y lugar reportado de defunción, edad y sexo del paciente, pero no existe información completa del establecimiento (muchos pacientes mueren en domicilio particular) ni tampoco de la previsión de salud que tenía la persona fallecida. </p>
      <h2 className="font-bold  text-xl text-blue-900 mt-5 uppercase">Filtros Y Comparaciones</h2>
      <p className="mt-5 text-md"><span className="text-blue-900 font-bold ">ÁREA GEOGRÁFICA:</span> La visualización incluye la posibilidad de seleccionar un área geográfica en particular,
      la cual está asignada en función del establecimiento del cual egresó el paciente.</p>
      <p className="mt-5 text-md"><span className="text-blue-900 font-bold ">DIAGNÓSTICO:</span> Entre los filtros disponibles se encuentra el diagnóstico principal, con posibilidad de
      buscar directamente el código CIE10, o de incorporar un capítulo, grupo, categoría, o subcategoría utilizando el sistema de búsqueda integrado.</p>
      <p className="mt-5 text-md"><span className="text-blue-900 font-bold ">EDAD:</span> es posible filtrar por edad, definiendo un rango etario.
      Se considera la edad del paciente en años al momento del ingreso.</p>
      <p className="mt-5 text-md"><span className="text-blue-900 font-bold ">DEPENDENCIA DEL CENTRO DE SALUD:</span> dependencia del centro de salud donde se produce el ingreso (dependiente del SNS o no dependiente, donde se incluyen los centros particulares y de dependencia de FFAA y Carabineros).</p>
      <p className="mt-5 text-md"><span className="text-blue-900 font-bold ">COMPARACIONES:</span>  la visualización permite comparar por edad, tramos agrupados cada 20 años; sexo: hombre o mujer, excluyendo los sin información y los no identificados; y tipo de previsión: Fonasa, ISAPRE, CAPREDENA, DIPRECA y SISA (seguridad social Argentina).  </p>
      <h2 className="font-bold  text-xl text-blue-900 mt-5 uppercase">Observaciones</h2>
      <ul className="list-disc mt-5">
        <li className="my-3">
          La data se presenta sin manipulaciones de ningún tipo, y en nuestras visualizaciones preliminares hemos detectado problemas que seguramente surgen de fallos en el reporte de las fichas de egreso hospitalario, especialmente en los primeros años del registro. 
        </li>
        <li className="my-3">
          Consideramos todos los datos que pudieron ser asignados a una visualización en particular, es por lo que, en desagregaciones geográficas por comuna pudiéramos no recuperar la data de visualizaciones más agregadas. Esto es especialmente relevante cuando los pacientes egresaron de centros médicos que ya no operan y que no fuimos capaces de ubicar exactamente en el mapa. 
        </li>
        <li className="my-3">
          Se adjunta una copia de la ficha de registro de egreso hospitalario y también de un tutorial en video de cómo interactuar con la plataforma.
        </li>
      </ul>
      
      
        <figure>
          <a className="flex flex-col" href={Ficha} download="ficha.jpg">
            <img className="h-40 cursor-pointer object-contain hover:scale-105 transition-all" src={Ficha} alt="Ficha de Egreso"/>
          </a>
          <figcaption className="text-slate-600 text-sm text-center mt-3">Ficha de Egreso Hospitalario</figcaption>
        
        </figure>
      
      
    </main>
    <footer className="bg-slate-100">
        <div className="container mx-auto p-10">
        <p className="text-left text-slate-800 text-md font-bold tracking-wider">
          Susana Mondschein, Felipe Subiabre, Natalia Yankovic
        </p>

        <p className="text-left text-slate-600 text-sm font-bold  mt-2 tracking-wide">
          Desarrollado por Sebastián C. Espinoza
        </p>
        <p className="text-left text-slate-600 text-sm font-bold  mt-2 tracking-wider">
          Contacto:{" "}
          <a className="italic " href="mailto:plataformasalud@isci.cl">
            plataformasalud@isci.cl
          </a>
        </p>

        <div className="flex justify-left items-center gap-6 mt-6">
          <img src={ISCILogo} alt="Logo ISCI" className="h-8" />
          <img
            src={INDLogo}
            alt="Logo Ingenierìa Industrial Universidad de Chile"
            className="h-10"
          />
        </div>
        </div>
      </footer>
    </>
  );
};

export default Docs;
