import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
const DashboardButton = ({ isVisible, setVisible }) => {
  if (!isVisible) {
    return (
      <div className="absolute bottom-20 md:bottom-[auto] md:top-4 right-4 flex items-center ">
        <button
          className="text-blue-900 text-xs md:text-md rounded-full bg-slate-100 shadow-md p-3 hover:bg-blue-900 hover:text-slate-100 transition-all"
          onClick={() => setVisible(true)}
        >
          <div className="flex justify-center items-center gap-2">
            
            <p className="font-bold leading-1">Mostrar gráficos</p>
            <FontAwesomeIcon className="block" icon={faChartLine} />
          </div>
        </button>
      </div>
    );
  }
  return null;
};
export default DashboardButton;
