export const layersConfig = [
  {
    name: "regions",
    showName: "Regiones",
    displayType: "Región",
    type: "vector",
    url: "mapbox://secancinoe.ca6lprcp",
    "source-layer": "regiones",
    layerType: "polygon",
    promoteId: "id",
    paint: {
      "fill-color": "#ffe878",
      "fill-opacity": 0.9,
    },
    visibility: "visible",
    scope: ["hospital", "patient"],
  },
  {
    name: "comunas",
    showName: "Comunas",
    displayType: "Comuna",
    type: "vector",
    url: "mapbox://secancinoe.dg06ref8",
    "source-layer": "comunas",
    layerType: "polygon",
    promoteId: "id",
    paint: {
      "fill-color": "#ffe878",
      "fill-opacity": 0.9,
    },
    visibility: "none",
    scope: ["hospital", "patient"],
  },

  {
    name: "hospitals",
    showName: "Establecimientos de Salud",
    type: "vector",
    displayType: "Establecimiento de Salud",
    layerType: "circle",
    url: "mapbox://secancinoe.bgi6djxi",
    "source-layer": "hospitales",
    promoteId: "id",
    paint: {
      "fill-color": "#ffe878",
      "fill-opacity": 0.9,
    },
    visibility: "none",
    scope: ["hospital"],
  },
];
