export const toggleFeatureState = (map, featureProps, state) => {
  const featureState = map.getFeatureState(featureProps);
  map.setFeatureState(featureProps, {
    ...featureState,
    [state]: !featureState[state],
  });
};

export const removeFeatureState = (map, featureProps, state) => {
  const featureState = map.getFeatureState(featureProps);
  map.setFeatureState(featureProps, { ...featureState, [state]: false });
};

export const addFeatureState = (map, featureProps, state) => {
  const featureState = map.getFeatureState(featureProps);
  map.setFeatureState(featureProps, { ...featureState, [state]: true });
};
