import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Chart from "../Chart/Chart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faMaximize,
  faMinimize,
  faSpinner,
  faBedPulse,
  faCalendarDays,
  faHospital,
  faCross,
  faFilter,
  faCheck,
  faXmark,
  faStarOfLife,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import Dropdown from "../Dropdown/Dropdown";
import { useComponentVisible } from "../../hooks/interactivity";
import { useCIE10, useData } from "../../hooks/data";


const DashboardButton = ({ className, icon, onClick }) => (
  <div
    className={`flex rounded-full h-6 aspect-square text-blue-900 hover:text-white hover:bg-blue-900 border-blue-900 cursor-pointer transition-all items-center justify-center ${className}`}
    onClick={onClick}
  >
    <FontAwesomeIcon className="text-sm" icon={icon} />
  </div>
);

const VariableControl = ({
  selectedLayer,
  selectedVariable,
  setSelectedVariable,
}) => {
  useEffect(() => {
    if (
      selectedLayer === "Establecimiento de Salud" &&
      selectedVariable === "defunciones"
    )
      setSelectedVariable("actividad");
  }, [selectedLayer, selectedVariable, setSelectedVariable]);
  return (
    <div>
      <h3 className="text-blue-900 font-bold">Variable</h3>
      <div className={`flex flex-wrap gap-1 mt-1 w-full`}>
        <div
          className={`flex gap-1 p-1.5 px-3 items-center justify-center  text-white rounded-full cursor-pointer hover:bg-opacity-70 transition-all
                    ${
                      selectedVariable === "actividad"
                        ? "bg-[#82ca9d] bg-opacity-100 hover:bg-opacity-70"
                        : "bg-slate-600 bg-opacity-50 hover:bg-[#82ca9d] hover:bg-opacity-90"
                    }`}
          onClick={() => setSelectedVariable("actividad")}
        >
          <FontAwesomeIcon className="text-sm mr-1" icon={faHospital} />
          <p className="text-xs font-bold tracking-wide text-white inline">
            Actividad
          </p>
        </div>
        <div
          className={` flex  gap-1 p-1.5 px-3 items-center justify-center  text-white rounded-full cursor-pointer transition-all
                    ${
                      selectedVariable === "carga"
                        ? "bg-[#dc2626] bg-opacity-100 hover:bg-opacity-70"
                        : "bg-slate-600 bg-opacity-50 hover:bg-[#dc2626] hover:bg-opacity-90"
                    }`}
          onClick={() => setSelectedVariable("carga")}
        >
          <FontAwesomeIcon className="text-sm mr-1" icon={faBedPulse} />
          <p className="text-sm font-xs font-bold tracking-wide text-white-900">
            Carga
          </p>
        </div>

        <div
          className={`flex gap-1 p-1.5 px-3 items-center justify-center text-white rounded-full cursor-pointer hover:bg-opacity-70 transition-all
                    ${
                      selectedVariable === "dias_estada"
                        ? "bg-[#2596be] bg-opacity-100 hover:bg-opacity-70"
                        : "bg-slate-600 bg-opacity-50 hover:bg-[#2596be] hover:bg-opacity-90"
                    }`}
          onClick={() => setSelectedVariable("dias_estada")}
        >
          <FontAwesomeIcon className="text-sm mr-1" icon={faCalendarDays} />
          <p className="text-xs font-bold tracking-wide text-white inline">
            Días de Estada
          </p>
        </div>
        {selectedLayer !== "Establecimiento de Salud" && (
          <div
            className={`flex gap-1 p-1.5 px-3 items-center justify-center  text-white rounded-full cursor-pointer hover:bg-opacity-70 transition-all
                    ${
                      selectedVariable === "defunciones"
                        ? "bg-slate-900 bg-opacity-100 hover:bg-opacity-70"
                        : "bg-slate-600 bg-opacity-50 hover:bg-slate-900 hover:bg-opacity-90"
                    }`}
            onClick={() => setSelectedVariable("defunciones")}
          >
            <FontAwesomeIcon className="text-sm mr-1" icon={faCross} />
            <p className="text-xs font-bold tracking-wide font-sm text-white">
              Defunciones
            </p>
          </div>
        )}
      </div>
      {selectedVariable === "defunciones" && (
        <p className="text-sm mt-2">
          * Los datos de defunciones están disponibles hasta el año 2018.
        </p>
      )}
    </div>
  );
};

const FilterCIE10 = ({
  selectedCIE10,
  updateSelectedCodes,
  changeSelectedCIE10,
  setSearchTerm,
  searchTerm,
  suggestions,
  setSuggestions
}) => {
  const { ref } =
    useComponentVisible(false);
  const {selectedCodes} = useSelector(state => state.cie10);
  const dispatch = useDispatch();
  return (
    <div className="w-full flex my-2 flex-col">
      <label className="font-bold text-sm flex-grow flex-1">
        Filtrar por código CIE10{" "}
      </label>
      <div className="mt-2 flex">
      {selectedCodes.length > 0 && (
          <div className="flex space-x-2 flex-wrap">
            {selectedCodes.map((codes) => (
              <div
                key={codes.codigo}
                className="bg-teal-600 text-white rounded-md px-2 cursor-pointer hover:bg-teal-700"
              >
                <span key={codes.codigo}>{codes.codigo}</span>
                <FontAwesomeIcon
                  className="ml-2 hover:scale-105 transition-all"
                  onClick={() =>
                    dispatch({type: "@cie10/removeCode", payload: codes.codigo})
                  }
                  icon={faClose}
                />
              </div>
            ))}
          </div>
        )}
        <button className="relative aspect-square h-6 ml-2 rounded-full group bg-blue-500 hover:bg-blue-600 text-white" onClick={() => dispatch({type: "@cie10/showTree"})}>
              <div className="hidden group-hover:block absolute bg-slate-700 p-1 rounded-md left-full translate-x-1 -translate-y-1/2 top-1/2 bg-opacity-95 z-99">
                <span className="whitespace-nowrap">Agregar códigos</span>
              </div>
              <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>

      <div ref={ref} className="flex-grow flex-2">
        {/*
        <Dropdown
          options={CIE10_OPTIONS}
          selectedValue={selectedCIE10}
          onChange={(value) => {
            updateSelectedCodes([]);
            changeSelectedCIE10(value);
            setSearchTerm("");
          }}
        />
        */}
        {/*
        {selectedCIE10 !== "todos" && (
          <div className="flex items-center">
            <div className="w-full relative">
            <input
              className="w-full bg-blue-200 rounded-xl px-4 py-1 my-2 text-slate-700 placeholder-slate-500"
              type="text"
              placeholder={`Buscar por código o nombre de ${CIE10_OPTIONS.find(
                (o) => o.value === selectedCIE10
              ).text.toLowerCase()}`}
              onChange={(event) => {
                setSearchTerm(event.target.value);
                setIsComponentVisible(true);
              }}
              onClick={() => setIsComponentVisible(true)}
              value={searchTerm}
            ></input>
            {suggestions.length > 0 && isComponentVisible && (
              <div className="absolute w-full h-60 overflow-y-scroll z-50 bg-blue-200 text-slate-700 rounded-md shadow-md overflow-hidden cursor-pointer bg-opacity-90 backdrop-blur-sm">
                <ul>
                  {suggestions.map((s) => (
                    <li
                      onClick={() => {
                        setSuggestions([]);
                        setSearchTerm("");
                        if (
                          !selectedCodes.map((c) => c.codigo).includes(s.codigo)
                        ) {
                          updateSelectedCodes([...selectedCodes, s]);
                        }
                      }}
                      className="py-2 px-4 hover:bg-blue-300 transition-all"
                      key={s.codigo}
                    >{`${s.codigo} ${s.glosa}`}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          </div>
        )}
        */}

        
      </div>
    </div>
  );
};

const FilterDependency = ({ dependency, setDependency }) => {
  return (
    <div className="flex mt-2 gap-1 flex-col">
      <p>Filtrar establecimientos de salud</p>
      <Dropdown
        className="w-full"
        selectedValue={dependency}
        options={[
          { value: "all", text: "Todos" },
          { value: 0, text: "No pertenecientes al SNSS" },
          { value: 1, text: "Pertenecientes al SNSS" },
        ]}
        onChange={(value) => setDependency(value)}
      />
    </div>
  );
};
const FilterAge = ({ minAge, maxAge, setMinAge, setMaxAge }) => {
  const [selectedMinAge, setSelectedMinAge] = useState(minAge ? minAge : 0);
  const [selectedMaxAge, setSelectedMaxAge] = useState(maxAge ? maxAge : 120);
  return (
    <div className="flex w-full gap-2 mt-2 items-end">
      <div className="flex flex-col w-32">
        <label className="text-sm font-bold" htmlFor="minAge">
          Edad mínima
        </label>
        <input
          id="minAge"
          className="bg-slate-200 rounded-xl text-right"
          type="number"
          value={selectedMinAge || "0"}
          min="0"
          max={selectedMaxAge}
          onChange={({ target }) => setSelectedMinAge(target.value)}
        ></input>
      </div>
      <div className="flex flex-col w-32">
        <label className="text-sm font-bold" for="minAge">
          Edad máxima
        </label>
        <input
          className="bg-slate-200 rounded-xl text-right"
          type="number"
          min={selectedMinAge}
          max="120"
          value={selectedMaxAge || "120"}
          onChange={({ target }) => setSelectedMaxAge(target.value)}
        ></input>
      </div>
      <button
        className="text-center bg-blue-900 text-white rounded-full h-5 aspect-square flex justify-center items-center hover:bg-blue-600 cursor-pointer"
        onClick={() => {
          setSelectedMinAge(0);
          setSelectedMaxAge(120);
          setMinAge(null);
          setMaxAge(null);
        }}
      >
        <FontAwesomeIcon className="text-xs" icon={faXmark} />
      </button>
      <button
        className="text-center bg-blue-900 text-white rounded-full h-5 aspect-square flex justify-center items-center hover:bg-blue-600 cursor-pointer"
        onClick={() => {
          setMinAge(selectedMinAge);
          setMaxAge(selectedMaxAge);
        }}
      >
        <FontAwesomeIcon className="text-xs" icon={faCheck} />
      </button>
    </div>
  );
};

const DashboardActions = ({
  isMaximized,
  toggleMaximized,
  mapRef,
  setVisible,
}) => {
  return (
    <div className="flex flex-row space-x-2">
      {/*<DashboardButton
        icon={faDownload}
        onClick={() =>
          window.open(
            `${process.env.REACT_APP_API_URL}/download/?start=${
              dateRange.start
            }&end=${dateRange.end}&${
              selected
                ? `${_.deburr(selected.type.toLowerCase().split(" ")[0])}=${
                    selected.id
                  }`
                : ""
            }${
              selectedCodes.length > 0
                ? `&${selectedCIE10}=${selectedCodes
                    .map((c) => c.codigo)
                    .join(",")}`
                : ""
            }`,
            "_blank"
          )
        }
      ></DashboardButton>*/}
      <DashboardButton
        className="hidden lg:flex"
        icon={isMaximized ? faMinimize : faMaximize}
        onClick={() => toggleMaximized(!isMaximized)}
      ></DashboardButton>
      <DashboardButton
        icon={faClose}
        onClick={() => setVisible(false)}
      ></DashboardButton>
    </div>
  );
};

const FilterControls = ({
  selectedVariable,
  selectedLayer,
  group,
  filter,
  setFilter,
  selectedCodes = [],
  selectedDependency = null,
  dependency,
  minAge,
  maxAge,
}) => {
  return (
    <div>
      <h3 className="text-blue-900 font-bold mt-2">Filtrar por</h3>
      <div className="flex justify-left gap-1 mt-1 items-start">
        <button
          className={` flex items-center gap-2 text-sm transition-all
          ${
            filter === "diagnostico"
              ? "bg-blue-900"
              : selectedCodes.length > 0
              ? "bg-blue-400"
              : "bg-slate-500"
          } text-white px-3 py-1 rounded-full`}
          onClick={() => {
            if (filter !== "diagnostico") {
              setFilter("diagnostico");
            } else {
              setFilter(null);
            }
          }}
        >
          Diagnóstico
          {selectedCodes.length > 0 && <FontAwesomeIcon icon={faFilter} />}
          {filter === "diagnostico" && <FontAwesomeIcon icon={faClose} />}
        </button>
        {group !== "edad" && (
          <button
            className={`flex items-center gap-2 text-sm transition-all
          ${
            filter === "edad"
              ? "bg-blue-900"
              : minAge !== null && maxAge !== null
              ? "bg-blue-400"
              : "bg-slate-500"
          } text-white px-3 py-1 rounded-full`}
            onClick={() => {
              if (filter !== "edad") {
                setFilter("edad");
              } else {
                setFilter(null);
              }
            }}
          >
            Edad
            {minAge !== null && maxAge !== null ? (
              <FontAwesomeIcon icon={faFilter} />
            ) : null}
            {filter === "edad" && <FontAwesomeIcon icon={faClose} />}
          </button>
        )}
        {
          ((!selectedLayer || selectedLayer !== 'Establecimiento de Salud') && selectedVariable !== 'defunciones') &&
        <button
          className={` flex items-center gap-2 text-sm transition-all
          ${
            filter === "dependencia"
              ? "bg-blue-900"
              : selectedDependency
              ? "bg-blue-400"
              : "bg-slate-500"
          } text-white px-3 py-1 rounded-full`}
          onClick={() => {
            if (filter !== "dependencia") {
              setFilter("dependencia");
            } else {
              setFilter(null);
            }
          }}
        >
          Dependencia
          {dependency !== "all" ? <FontAwesomeIcon icon={faFilter} /> : null}
          {filter === "dependency" && <FontAwesomeIcon icon={faClose} />}
        </button>
        }
              {selectedCodes.length > 0 || (minAge !== null && maxAge !== null) ? (
          <p className="ml-auto text-xs text-left rounded-full p-1 px-2 font-bold text-blue-900">
            <FontAwesomeIcon
              className="mr-1 text-blue-500"
              icon={faStarOfLife}
            />{" "}
            El ícono <FontAwesomeIcon icon={faFilter} /> indica los filtros
            activos.
          </p>
        ) : null}
        
      </div>

    </div>
  );
};
const GroupControls = ({
  selectedVariable,
  group,
  setGroup,
  minAge,
  maxAge,
}) => {
  const {selectedCodes} = useSelector(state => state.cie10);
  useEffect(() => {
    if (selectedCodes.length < 2 && group === "diagnostico") {
      setGroup(null);
    }
  }, [group, selectedCodes, setGroup]);
  return (
    <div>
      <h3 className="text-blue-900 font-bold mt-2">Comparar por</h3>
      <div className="flex justify-left gap-1 mt-1">
        {minAge === null && maxAge === null ? (
          <button
            className={` flex items-center gap-2 text-sm transition-all
          ${
            group === "edad" ? "bg-blue-900" : "bg-slate-500"
          } text-white px-3 py-1 rounded-full`}
            onClick={() => {
              if (group !== "edad") {
                setGroup("edad");
              } else {
                setGroup(null);
              }
            }}
          >
            Edad
            {group === "edad" && <FontAwesomeIcon icon={faClose} />}
          </button>
        ) : null}
        <button
          className={`flex items-center gap-2 text-sm transition-all
          ${
            group === "sexo" ? "bg-blue-900" : "bg-slate-500"
          } text-white px-3 py-1 rounded-full`}
          onClick={() => {
            if (group !== "sexo") {
              setGroup("sexo");
            } else {
              setGroup(null);
            }
          }}
        >
          Sexo
          {group === "sexo" && <FontAwesomeIcon icon={faClose} />}
        </button>
        {selectedVariable !== "defunciones" && (
          <button
            className={`flex items-center gap-2 text-sm transition-all
          ${
            group === "prevision" ? "bg-blue-900" : "bg-slate-500"
          } text-white px-3 py-1 rounded-full`}
            onClick={() => {
              if (group !== "prevision") {
                setGroup("prevision");
              } else {
                setGroup(null);
              }
            }}
          >
            Previsión
            {group === "prevision" && <FontAwesomeIcon icon={faClose} />}
          </button>
        )}
        {selectedCodes.length >= 2 && selectedCodes.length <= 5 && (
          <button
            className={`flex items-center gap-2 text-sm transition-all
          ${
            group === "diagnostico" ? "bg-blue-900" : "bg-slate-500"
          } text-white px-3 py-1 rounded-full`}
            onClick={() => {
              if (group !== "diagnostico") {
                setGroup("diagnostico");
              } else {
                setGroup(null);
              }
            }}
          >
            Diagnóstico
            {group === "diagnostico" && <FontAwesomeIcon icon={faClose} />}
          </button>
        )}
      </div>
    </div>
  );
};
const ChartContainer = ({
  group,
  isMaximized,
  data,
  loading,
  selectedVariable,
  selectedCodes,
}) => {
  return (
    <div
      className={`relative w-full mt-5 transition-all duration-1000  box-border`}
    >
      {data.length === 0 ? (
        <div className="absolute flex items-center justify-center inset-0 h-full w-full bg-opacity-50 bg-slate-700">
          <p className="animate-pulse text-white text-4xl ">Sin Datos</p>
        </div>
      ) : (
        <Chart
          data={data}
          group={group}
          loading={loading}
          isMaximized={isMaximized}
          selectedVariable={selectedVariable}
          selectedCodes={selectedCodes}
        />
      )}

      {loading && (
        <div className="absolute flex items-center justify-center inset-0 h-full w-full bg-opacity-50 bg-slate-700">
          <FontAwesomeIcon
            className="animate-spin text-white text-4xl "
            icon={faSpinner}
          />
        </div>
      )}
    </div>
  );
};
const Dashboard = ({ mapRef, visible, setVisible }) => {
  const { selected } = useSelector((state) => state.features);
  const { dateRange } = useSelector((state) => state.dates);
  const [dependency, setDependency] = useState("all");
  const [group, setGroup] = useState(null);
  const [filter, setFilter] = useState(null);
  const [isMaximized, toggleMaximized] = useState(false);
  const [selectedVariable, setSelectedVariable] = useState("actividad");
  const [minAge, setMinAge] = useState(null);
  const [maxAge, setMaxAge] = useState(null);
  const [
    selectedCIE10,
    changeSelectedCIE10,
    selectedCodes,
    updateSelectedCodes,
    searchTerm,
    setSearchTerm,
    suggestions,
    setSuggestions,
  ] = useCIE10();
  const cie10 = useSelector(state => state.cie10);
  const [data, isLoading] = useData({
    selectedCodes: cie10.selectedCodes,
    selectedCIE10: cie10.selectedLevel,
    group,
    minAge,
    maxAge,
    metric: selectedVariable,
    dependency,
  });

  return (
    <div
      className={`absolute right-0 m-0 h-screen resize-horizontal top-0 box-border
                  overflow-x-visible
                  overflow-y-scroll ${
                    isMaximized ? "w-full" : "w-full xl:w-[490px] 2xl:w-[600px]"
                  } 
                 bg-white z-10 py-4 shadow-md transition-all duration-1000
                 
      `}
      style={{
        transform: `${
          visible
            ? isMaximized
              ? "translateY(0%)"
              : "translateX(0)"
            : isMaximized
            ? "translateY(-100%)"
            : "translateX(100%)"
        }`,
        opacity: visible ? 1 : 0,
      }}
    >
      <div
        className={`relative box-border px-4 h-full ${
          isMaximized ? "container mx-auto" : "w-full px-4"
        }`}
      >
        <div className="flex flex-row justify-between items-start ">
          <div>
            {selected ? (
              <h3 className="text-gray-600 text-md font-medium tracking-wider">
                <span className="font-bold text-blue-900">{`${selected.type}`}</span>
                <br />
                {selected.name}
              </h3>
            ) : (
              <h3 className="text-gray-600 text-md font-medium tracking-wider">
                <span className="font-bold text-blue-900">Total País</span>
                <br />
                Chile
              </h3>
            )}
          </div>
          <DashboardActions
            dateRange={dateRange}
            selected={selected}
            selectedCodes={cie10.selectedCodes}
            setVisible={setVisible}
            isMaximized={isMaximized}
            selectedCIE10={selectedCIE10}
            toggleMaximized={toggleMaximized}
            mapRef={mapRef}
          />
        </div>

        <div className="w-full h-full flex flex-col mt-3">
          <VariableControl
            selectedLayer={selected && selected.type}
            selectedVariable={selectedVariable}
            setSelectedVariable={setSelectedVariable}
          />
          <FilterControls
            selectedVariable={selectedVariable}
            selectedLayer={selected&&selected.type}
            group={group}
            filter={filter}
            setFilter={setFilter}
            selectedCodes={cie10.selectedCodes}
            dependency={dependency}
            minAge={minAge}
            maxAge={maxAge}
          />
          {filter === "diagnostico" && (
            <>
              <FilterCIE10
                selectedCIE10={selectedCIE10}
                updateSelectedCodes={updateSelectedCodes}
                changeSelectedCIE10={changeSelectedCIE10}
                setSearchTerm={setSearchTerm}
                searchTerm={searchTerm}
                suggestions={suggestions}
                setSuggestions={setSuggestions}
                selectedCodes={selectedCodes}
              />
              
            </>
          )}

          {filter === "dependencia" && (
            <FilterDependency
              dependency={dependency}
              setDependency={setDependency}
            />
          )}

          {filter === "edad" && group !== "edad" ? (
            <FilterAge
              minAge={minAge}
              maxAge={maxAge}
              setMinAge={setMinAge}
              setMaxAge={setMaxAge}
            />
          ) : null}
          <GroupControls
            selectedVariable={selectedVariable}
            group={group}
            setGroup={setGroup}
            selectedCodes={selectedCodes}
            minAge={minAge}
            maxAge={maxAge}
          />
          <ChartContainer
            isMaximized={isMaximized}
            data={data}
            group={group}
            loading={isLoading}
            selectedVariable={selectedVariable}
            selectedCodes={selectedCodes}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
