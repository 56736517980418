const initialState = {
  selectedLayer: "regions",
  scope: "hospital",
};

export const layersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "@layers/changeSelected":
      return {
        ...state,
        selectedLayer: action.payload,
      };
    case "@layers/changeScope":
      return {
        ...state,
        scope: action.payload.scopeValue,
        selectedLayer: action.payload.newLayer,
      };
    default:
      return state;
  }
};
