import { store } from "../store";
import { removeFeatureState, addFeatureState } from "./features";
import { unselectFeature, selectFeature } from "../middleware/map";
export const createLayer = (map, layerOptions) => {
  // add source
  map.addSource(layerOptions.name, {
    type: layerOptions.type,
    url: layerOptions.url,
    promoteId: layerOptions.promoteId,
    tolerance: 0.9,
  });
  // create fill layer
  if (layerOptions.layerType === "polygon") {
    map.addLayer(
      {
        id: `${layerOptions.name}-fill`,
        type: "fill",
        source: layerOptions.name,
        "source-layer": layerOptions["source-layer"],
        layout: { visibility: layerOptions.visibility },
        paint: {
          "fill-color": "#7fc97f",
          "fill-opacity": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            0.8,
            ["boolean", ["feature-state", "selected"], false],
            1,
            0.2,
          ],
          "fill-opacity-transition": {
            duration: 1000,
            delay: 0,
          },

          "fill-color-transition": { duration: 10000 },
        },
      },
      "bridge-rail"
    );
    // create stroke layer
    map.addLayer(
      {
        id: `${layerOptions.name}-stroke`,
        type: "line",
        source: layerOptions.name,
        "source-layer": layerOptions["source-layer"],
        layout: { visibility: layerOptions.visibility },
        paint: {
          "line-color": "#fff",
          "line-width": [
            "case",
            ["boolean", ["feature-state", "selected"], false],
            2,
            1,
          ],
        },
      },
      "waterway-label"
    );
  }

  if (layerOptions.layerType === "circle") {
    map.addLayer({
      id: `${layerOptions.name}-fill`,
      type: "circle",
      source: layerOptions.name,
      "source-layer": layerOptions["source-layer"],
      layout: { visibility: layerOptions.visibility },
      paint: {
        "circle-radius": {
          stops: [
            [8, 3],
            [11, 6],
            [16, 8],
          ],
        },
        "circle-color": "#7fc97f",
        "circle-stroke-width": [
          "case",
          ["boolean", ["feature-state", "selected"], false],
          2,
          1,
        ],
        "circle-stroke-color": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          "#fff",
          ["boolean", ["feature-state", "selected"], false],
          "#1e3a8a",
          "#777",
        ],
        "circle-opacity": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          0.7,
          ["boolean", ["feature-state", "selected"], false],
          1,
          0.5,
        ],
      },
    });
  }

  // mousevents
  map.on("mousemove", `${layerOptions.name}-fill`, (event) => {
    map.getCanvas().style.cursor = "pointer";
    const { features } = store.getState();
    const { hovered } = features;
    if (hovered) {
      const featureProps = {
        source: layerOptions.name,
        sourceLayer: layerOptions["source-layer"],
        id: hovered,
      };
      store.dispatch({ type: "@features/unhoverFeature" });
      removeFeatureState(map, featureProps, "hover");
    }
    const newHovered = event.features[0].id;
    const newName = event.features[0].properties.name;

    store.dispatch({
      type: "@features/hoverFeature",
      payload: { featureId: newHovered, featureName: newName },
    });
    const featureProps = {
      source: layerOptions.name,
      sourceLayer: layerOptions["source-layer"],
      id: newHovered,
    };
    addFeatureState(map, featureProps, "hover");
  });
  map.on("mouseleave", `${layerOptions.name}-fill`, () => {
    const { features } = store.getState();
    const { hovered } = features;

    if (hovered) {
      const featureProps = {
        source: layerOptions.name,
        sourceLayer: layerOptions["source-layer"],
        id: hovered,
      };
      removeFeatureState(map, featureProps, "hover");
      store.dispatch({ type: "@features/unhoverFeature" });
    }
  });

  map.on("click", `${layerOptions.name}-fill`, (e) => {
    e.preventDefault();
    unselectFeature({ current: map });
    selectFeature({ current: map }, e.features[0].properties);
    const {centerX, centerY} = e.features[0].properties;
      map.flyTo({
        center: [centerX, centerY],
        zoom: layerOptions.name === 'hospital' ? 14: 7,
        duration: 1500,
      });
  });

  map.on("click", (e) => {
    if (e.defaultPrevented) return;
    const { features } = store.getState();
    const { selected } = features;
    
    if (selected) {
      unselectFeature({ current: map });
      store.dispatch({
        type: "@features/unselectFeature",
      });
    }
  });
};
