import _ from "lodash";
import moment from "moment";
import { toast } from "react-toastify";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const METRICS_MAP = {
  actividad: "act",
  dias_estada: "dest",
  carga: "car",
  defunciones: "def",
};

const getEndpoint = (scope, period, group, metric = "actividad") => {
  if (!group) {
    if (metric === "defunciones") {
      return `${process.env.REACT_APP_API_URL}/metrics/${METRICS_MAP[metric]}/${period}/`;
    }
    return `${process.env.REACT_APP_API_URL}/metrics/${METRICS_MAP[metric]}/${scope}/${period}/`;
  }
  if (group) {
    if (metric === "defunciones") {
      return `${process.env.REACT_APP_API_URL}/metrics/${METRICS_MAP[metric]}/${group}/${period}/`;
    }
    return `${process.env.REACT_APP_API_URL}/metrics/${METRICS_MAP[metric]}/${group}/${scope}/${period}/`;
  }
};
export const useCIE10 = () => {
  const [selectedCIE10, changeSelectedCIE10] = useState("todos");
  const [selectedCodes, updateSelectedCodes] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  useEffect(() => {
    // cie10 codes effect

    if (searchTerm === null || selectedCIE10 === "todos") {
      setSuggestions([]);
      return;
    }
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/cie10/${selectedCIE10}s/?search=${searchTerm}`
      )
      .then((response) => setSuggestions(response.data));
  }, [searchTerm, selectedCIE10]);

  return [
    selectedCIE10,
    changeSelectedCIE10,
    selectedCodes,
    updateSelectedCodes,
    searchTerm,
    setSearchTerm,
    suggestions,
    setSuggestions,
  ];
};

export const useData = ({
  selectedCodes,
  selectedCIE10,
  dependency,
  group,
  minAge,
  maxAge,
  metric,
}) => {
  const { selected } = useSelector((state) => state.features);
  const { scope } = useSelector((state) => state.layers);
  const { dateRange, period } = useSelector((state) => state.dates);
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const selectedCodesDep = JSON.stringify(selectedCodes);

  useEffect(() => {
    setLoading(true);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const endpoint = getEndpoint(scope, period, group, metric);
    toast
      .promise(
        axios.get(
          `${endpoint}?start=${dateRange.start}&end=${dateRange.end}${
            selected
              ? `&${_.deburr(selected.type.toLowerCase().split(" ")[0])}=${
                  selected.id
                }`
              : ""
          }${dependency !== "all" ? `&pertenencia=${dependency}` : ""}${
            selectedCodes.length > 0
              ? `&${selectedCIE10}=${selectedCodes
                  .map((c) => c.codigo)
                  .join(",")}`
              : ""
          }${
            minAge !== null && maxAge !== null
              ? `&ageRange=${minAge},${maxAge}`
              : ""
          }`,
          {
            cancelToken: source.token,
          }
        ),
        {
          pending: "Descargando Datos",
          success: "¡Listo!",
          error: {
            render({data}) {
              if (data.message === 'canceled') {
                return "Operación Cancelada"
              }
              return "Ocurrió un error al solicitar los datos."
            }
          },
        },
        {
          autoClose: 1000,
          hideProgressBar: true,
        }
      )
      .then((response) => {
        setData(
          response.data.map((d) => ({
            ...d,
            fecha: moment(d.fecha).unix() * 1000,
            actividad: parseInt(d.actividad),
            carga: parseInt(d.carga),
            dias_estada: parseFloat(d.dias_estada),
          }))
        );
        setLoading(false);
      });
      return () => {
        source.cancel();
      }
    // eslint-disable-next-line
  }, [
    selected,
    selectedCodesDep,
    dateRange,
    period,
    scope,
    group,
    minAge,
    maxAge,
    metric,
    dependency,
  ]);
  return [data, isLoading];
};

export const useGeoData = (layer) => {
  const [features, setFeatures] = useState([]);
  useEffect(() => {
    const MAP_LAYER_TO_LEVEL = {
      comunas: "comunas",
      regions: "regiones",
      hospitals: "establecimientos",
    };
    axios
      .get(`${process.env.REACT_APP_API_URL}/geo/${MAP_LAYER_TO_LEVEL[layer]}`)
      .then((response) => setFeatures(response.data))
      .catch(() => setFeatures([]));
  }, [layer]);

  return features;
};
