export const dataIntegrity = [
    {
      "anio_egreso": 2001,
      "n_fecha": "1506960",
      "n": "1566189",
      "p": "96.2182725073410680"
    },
    {
      "anio_egreso": 2002,
      "n_fecha": "1549893",
      "n": "1599076",
      "p": "96.9242862753239996"
    },
    {
      "anio_egreso": 2003,
      "n_fecha": "1565913",
      "n": "1599280",
      "p": "97.9136236306337852"
    },
    {
      "anio_egreso": 2004,
      "n_fecha": "1592990",
      "n": "1627749",
      "p": "97.8645970601118477"
    },
    {
      "anio_egreso": 2005,
      "n_fecha": "1627743",
      "n": "1627743",
      "p": "100.0000000000000000"
    },
    {
      "anio_egreso": 2006,
      "n_fecha": "1637916",
      "n": "1637920",
      "p": "99.9997557878284654"
    },
    {
      "anio_egreso": 2007,
      "n_fecha": "1627130",
      "n": "1632888",
      "p": "99.6473732429903337"
    },
    {
      "anio_egreso": 2008,
      "n_fecha": "1608540",
      "n": "1608540",
      "p": "100.0000000000000000"
    },
    {
      "anio_egreso": 2009,
      "n_fecha": "1682045",
      "n": "1682054",
      "p": "99.9994649398889691"
    },
    {
      "anio_egreso": 2010,
      "n_fecha": "1623875",
      "n": "1623875",
      "p": "100.0000000000000000"
    },
    {
      "anio_egreso": 2011,
      "n_fecha": "1648677",
      "n": "1648687",
      "p": "99.9993934567325393"
    },
    {
      "anio_egreso": 2012,
      "n_fecha": "1670447",
      "n": "1670447",
      "p": "100.0000000000000000"
    },
    {
      "anio_egreso": 2013,
      "n_fecha": "1676931",
      "n": "1676936",
      "p": "99.9997018371601540"
    },
    {
      "anio_egreso": 2014,
      "n_fecha": "1660151",
      "n": "1660151",
      "p": "100.0000000000000000"
    },
    {
      "anio_egreso": 2015,
      "n_fecha": "1671054",
      "n": "1671054",
      "p": "100.0000000000000000"
    },
    {
      "anio_egreso": 2016,
      "n_fecha": "1637265",
      "n": "1637265",
      "p": "100.0000000000000000"
    },
    {
      "anio_egreso": 2017,
      "n_fecha": "1637150",
      "n": "1637150",
      "p": "100.0000000000000000"
    },
    {
      "anio_egreso": 2018,
      "n_fecha": "1669602",
      "n": "1669602",
      "p": "100.0000000000000000"
    },
    {
      "anio_egreso": 2019,
      "n_fecha": "1636508",
      "n": "1636508",
      "p": "100.0000000000000000"
    }
  ];