import { faChevronDown, faPlus, faClose, faMinus, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import cie10 from '../../helpers/cie10.json';

const Cie10Division = (props) => {
  const { selectedCodes, selectedLevel, setSelectedLevel, members, codigo, glosa, tipo, searchTerm, setSelectedCodes, color = 900 } = props;
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if(searchTerm.length > 0 && selectedLevel !== tipo) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
  }
  }, [setIsOpen, searchTerm, selectedLevel, tipo]);
  const selected = selectedCodes.map(c => c.codigo);
  const isSelected = selected.includes(codigo) && selectedLevel === tipo;
  return (
    <div className="flex flex-col">
      <div className={`${isSelected ? 'bg-blue-900': (tipo === selectedLevel ? "bg-teal-300": "bg-blue-200")} text-sm p-2 px-4 rounded-md ${isSelected ? "text-slate-100": "text-slate-700"} flex justify-between items-center`}>
        <div className="flex gap-3">
          {
            members && members.length &&
            <button onClick={() => setIsOpen(value => !value)}>
                  <FontAwesomeIcon
                    className={` transition-all ${isOpen ? "-rotate-180" : ""}`}
                    icon={faChevronDown}
                  />
            </button>
          }
          <p className="max-w-90">
            <span className="font-bold">{tipo.toUpperCase()} | {codigo}</span>
            <br />
            <span>{glosa}</span>
          </p>
        </div>
        <button onClick={() => {
          if (selected.includes(codigo)) {
            setSelectedCodes(values => {
              const newValues = values.filter((c => c.codigo !== codigo))
              if (newValues.length === 0) setSelectedLevel(null);
              return newValues;
            });
            return;
          }
          if (tipo !== selectedLevel) {
            setSelectedLevel(tipo);
            setSelectedCodes([{codigo, glosa}]);
          }
          setSelectedCodes(values => {
            const codes = values.map(c => c.codigo)
            if (codes.includes(codigo)) return values;
            return [...values, {codigo, glosa}];
          })
        }}>
          {
            selected.includes(codigo)
            ? <>
              <span className="mr-2">Eliminar</span>
              <FontAwesomeIcon icon={faMinus} />
            </>
            : <>
              <span className="mr-2">Agregar</span>
              <FontAwesomeIcon icon={faPlus} />
            </>
          }
          
        </button>
      </div>
      {isOpen &&
      <div className="w-full pl-10">
        <ul className="flex flex-col gap-1 mt-2">
          {
            members && members.length && members.map(({ codigo, glosa, miembros, tipo }) => (
              <Cie10Division
                key={codigo}
                codigo={codigo}
                glosa={glosa}
                members={miembros}
                color={color-100}
                tipo={tipo}
                setSelectedCodes={setSelectedCodes}
                selectedLevel={selectedLevel}
                selectedCodes={selectedCodes}
                setSelectedLevel={setSelectedLevel}
                searchTerm={searchTerm}/>
            ))
            }
        </ul>
      </div>
}
    </div>
  );
};
const Cie10Tree = (props) => {
const cie10State = useSelector(state => state.cie10);
const [selectedLevel, setSelectedLevel] = useState(cie10State.selectedLevel);
const [searchTerm, setSearchTerm] = useState("");
const [selectedCodes, setSelectedCodes] = useState(() => cie10State.selectedCodes);

const filteredCIE10 = useMemo(() => {
  function filterCodes(codes) {
    const filteredCodes = [];
    codes.forEach(level => {
      const {codigo, glosa, miembros, tipo} = level;
      if (tipo === 'subcategoria') {
        if (codigo.toLowerCase().includes(searchTerm.toLowerCase()) || glosa.toLowerCase().includes(searchTerm.toLowerCase())) {
          filteredCodes.push({...level});
        }
      } else {
        const filteredMembers = filterCodes(miembros);
        if (filteredMembers.length > 0) {
          filteredCodes.push({...level, miembros: filteredMembers});
        } else if (codigo.toLowerCase().includes(searchTerm.toLowerCase()) || glosa.toLowerCase().includes(searchTerm.toLowerCase())){
          filteredCodes.push({...level, miembros: null});
        }
      }       
  })
    return filteredCodes;
    
  }
  return filterCodes(cie10);
  
}, [searchTerm])

  const dispatch = useDispatch();
  return (
    <div className="bg-slate-800 bg-opacity-50 inset-0 z-[999] backdrop-blur-xs absolute">
      <div className="relative max-h-[80vh] w-[90vw] bg-white top-1/2 left-1/2 rounded-lg -translate-x-1/2 -translate-y-1/2 shadow-md p-10">
        <FontAwesomeIcon className="absolute top-4 right-4 cursor-pointer" icon={faClose} onClick={() => {
          dispatch({type: "@cie10/hideTree"});
          }}/>
        <h2 className="text-xl font-bold text-blue-900">
          Buscador Códigos CIE10
        </h2>
        <input
          className="bg-blue-200 w-full rounded-lg text-sm p-2 mt-2"
          type="text"
          placeholder="Busca por código o glosa del diagnostico."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          />
        <div className="h-[400px] overflow-y-scroll mt-2">
          <ul className="flex flex-col space-y-1 mr-2">
            {filteredCIE10.map(({ codigo, glosa, miembros, tipo }) => (
              <li key={codigo}>
                <Cie10Division
                  key={codigo}
                  codigo={codigo}
                  glosa={glosa}
                  members={miembros}
                  selectedLevel={selectedLevel}
                  setSelectedLevel={setSelectedLevel}
                  selectedCodes={selectedCodes}
                  setSelectedCodes={setSelectedCodes}
                  searchTerm={searchTerm}
                  tipo={tipo}
                />
              </li>
            ))}
          </ul>
        </div>
        {
          selectedCodes.length > 0 && (
            <div className="mt-2 font-bold text-sm">
              <span className="text-slate-600">* Puedes agregar <span className="text-teal-500">{selectedLevel}s</span> a la selección para luego compararlos en el gráfico.
                  Si seleccionas un código en otra clasificación (destacados en <span className="text-blue-800">azul</span>), se borrará la seleccion actual.
              </span>
            </div>
          )
        }
        
        <div className="flex flex-col mt-2">
          <span className="text-blue-900 font-bold">Códigos Seleccionados:</span>
          <ul className="flex gap-1 flex-wrap mt-1">
            {
              selectedCodes.map(
                ({codigo, glosa}) =>
                  <li className="bg-teal-600 rounded-md py-1 px-2 text-slate-100 group relative cursor-default text-sm flex items-center">
                    <span
                      className="bg-slate-800 absolute -translate-y-full -top-1 left-1/2 -translate-x-1/2
                                  p-1 rounded-md hidden group-hover:block text-sm
                                  ">
                        {glosa}
                    </span>
                    {codigo}
                    <span
                      onClick={() => {
                        setSelectedCodes(values => {
                          const newValues = values.filter((c => c.codigo !== codigo))
                          if (newValues.length === 0) setSelectedLevel(null);
                          return newValues;
                        });
                      }}
                    className="ml-2 cursor-pointer">
                      <FontAwesomeIcon icon={faClose} />
                    </span>
                  </li>
                )
            }
          </ul>
        </div>
        <div className="flex gap-1 justify-end">
          <button 
            onClick={() => {
              dispatch({type: "@cie10/hideTree"})
            }}
          className="bg-red-500 p-2 rounded-full text-white text-sm">
            Cerrar sin guardar cambios
            <FontAwesomeIcon className="ml-1" icon={faClose} />
          </button>
          <button 
            onClick={() => {
              dispatch({type: "@cie10/updateCodes", payload: selectedCodes});
              dispatch({type: "@cie10/updateLevel", payload: selectedLevel});
              dispatch({type: "@cie10/hideTree"})
            }}
          className="bg-teal-500 p-2 rounded-full text-white text-sm">
            
            Aplicar Cambios
            <FontAwesomeIcon className="ml-1" icon={faCheck} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Cie10Tree;
