import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMap } from "../../hooks/map";
import { createLayer } from "../../helpers/map";
import { layersConfig } from "../../config/layers";

const useLayers = (map) => {
  useEffect(() => {
    map.current.on("load", () => {
      layersConfig.forEach((layerConfig) => {
        createLayer(map.current, layerConfig);
      });
    });
  }, [map]);
};

const Map = ({ mapRef }) => {
  const mapContainer = useMap(mapRef);
  const layers = useSelector((state) => state.layers);
  const dispatch = useDispatch();
  useLayers(mapRef);

  useEffect(() => {
    if (!mapRef.current.isStyleLoaded()) return;
    //dispatch({ type: "@map/setLoaded" });
    layersConfig.forEach((layerConfig) => {
      mapRef.current.setLayoutProperty(
        `${layerConfig.name}-fill`,
        "visibility",
        "none"
      );
      if (layerConfig.layerType === "polygon") {
        mapRef.current.setLayoutProperty(
          `${layerConfig.name}-stroke`,
          "visibility",
          "none"
        );
      }
    });
    mapRef.current.setLayoutProperty(
      `${layers.selectedLayer}-fill`,
      "visibility",
      "visible"
    );
    if (
      layersConfig.find((layer) => layer.name === layers.selectedLayer)
        .layerType === "polygon"
    ) {
      mapRef.current.setLayoutProperty(
        `${layers.selectedLayer}-stroke`,
        "visibility",
        "visible"
      );
    }
  }, [layers, mapRef]);
  useEffect(() => {
    if (!mapRef.current) return;
    mapRef.current.on("load", () => dispatch({ type: "@map/setLoaded" }));
  }, [mapRef, dispatch]);
  return (
    <div ref={mapContainer} className="w-full h-full relative m-0 box-border" />
  );
};
export default Map;
