import { configureStore } from "@reduxjs/toolkit";
import { layersReducer } from "./reducers/layers";
import { featuresReducer } from "./reducers/features";
import { mapReducer } from "./reducers/map";
import { datesReducer } from "./reducers/dates";
import { cie10Reducer } from "./reducers/cie10";

export const store = configureStore({
  reducer: {
    layers: layersReducer,
    features: featuresReducer,
    map: mapReducer,
    dates: datesReducer,
    cie10: cie10Reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
  devTools: true,
});
